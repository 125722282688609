/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

// Images
import frameImageSrc from './images/frame.jpg'
import reactImageSrc from './images/react.png'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  center: css({
    margin: `${theme.spacing.xs} auto`,
    textAlign: `center`,
  }),
  settings: css({
    width: `100%`,

    [mq.tablet]: {
      display: `flex`,
      flexDirection: `column`,
      alignItems: `center`,
    },
  }),
  reset: css({
    width: `100%`,
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  input: css({
    width: `25%`,
    display: `inline-block`,
    margin: theme.spacing.xs,
    textAlign: `center`,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    backgroundColor: theme.color.secL3,

    [mq.tablet]: {
      margin: theme.spacing.xxs,
      width: `50%`,
    },
  }),
  turn: css({
    float: `right`,

    [mq.tablet]: {
      width: '100%',
      textAlign: 'right',
    },
  }),
  tableWrapper: css({
    margin: `${theme.spacing.md} auto ${theme.spacing.lg} auto`,
    backgroundColor: theme.color.secD2,
  }),
  cell: css({
    width: `100px`,
    height: `100px`,
  }),
  fill: css({
    width: `100%`,
    height: `100%`,
    backgroundColor: theme.color.main,
    backgroundImage: `url(${reactImageSrc})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `80%`,
    backgroundPosition: `center`,
  }),
  empty: css({
    width: `100%`,
    height: `100%`,
    backgroundImage: `url(${frameImageSrc})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SealBreaker = () => {
  // Initialization settings
  // We need starting grid for game, but we don't want to reset this value after each round !!
  const [init, setInit] = useState(true)
  // Current game layout, what we see in the game
  const [grid, setGrid] = useState([])

  // Current columns count, default is "3", but allowed limit is 3-5 only
  const [columns, setColumns] = useState(3)
  columns > 5 ? setColumns(5) : ''
  columns < 3 ? setColumns(3) : ''

  // Current rows count, default is "3", but allowed limit is 3-5 only
  const [rows, setRows] = useState(3)
  rows > 5 ? setRows(5) : ''
  rows < 3 ? setRows(3) : ''

  // Variables for our grid
  let newRow = [],
    newTile,
    // The tileIndex is subject to global scope
    tileIndex = 0

  // Operating settings
  // The player needs to make choices === -1 default OR reset
  const [click, setClick] = useState(-1)
  // Is round over ?
  const [newRound, setNewRound] = useState(false)
  // We want to count moves
  const [roundCounter, setRoundCounter] = useState(0)
  // Hidden game layout for checking win conditions
  const [isWin, setIsWin] = useState([])
  // Is game over ?
  const [endGame, setEndGame] = useState(false)

  // Game layout initialization, do it only once !!
  if (init === true) {
    // Create grid
    const CreateGrid = () => {
      // The first "for" represents rows
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        // The second "for" represents specific cells in rows (in another words === columns)
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          // Tile is specific "single" cell
          newTile = (
            <button css={s.empty} value={tileIndex} occupancy='true' key={columnIndex}></button>
          )
          // Add last tile into row
          newRow.push(newTile)
          // Increase next tile number by 1, the global scope is not reset between cycles !!
          tileIndex++
        }
        // Add last created row into array / grid
        grid.push(newRow)
        // Reset row for next round
        newRow = []
      }
    }
    // Deactivate initialization, only once is needed !!
    setInit(false)
    // Call function CreateGrid() for creating grid
    CreateGrid()
  }

  // Next round check, next round is after user's click ..
  // -1 is default for initialization or reset after round, it's not user's click
  // "undefined" check is a safeguard against an unexpected fatal error
  if (click !== -1 && click !== undefined) {
    // If it was clicked and there is a new round at the same time, prevents the endless loop of useEffect, waiting for the next user's click !!
    if (newRound === true) {
      // Reset new round status
      setNewRound(!newRound)
      // Reset user's click status
      setClick(-1)
    }
  }

  // Row index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 / 3 = 1,66.. rounded down it is 1 .. this result is index of our clicked row
  // Seal maker is variable for dynamic behavior adjacent tiles, if not specified, then "sealMaker" = 0
  const RowIndex = (id, sealMaker = 0) => {
    return Math.floor(id / columns - sealMaker)
  }

  // Column index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 % 3 = 2 .. this result is index of our clicked column
  // Seal maker is variable for dynamic behavior adjacent tiles, if not specified, then "sealMaker" = 0
  const ColumnIndex = (id, sealMaker = 0) => {
    return (id % columns) - sealMaker
  }

  // Grid render function
  // Tile refresh is a game mechanic for switching the status of tiles, if not specified, then "column" = 0 and "row" = 0
  const TileRefresh = (column = 0, row = 0) => {
    let isEmpty, bgSwitch
    isEmpty =
      // Check if is tile empty
      grid[RowIndex(click, row)][ColumnIndex(click, column)].props.occupancy === 'true'
        ? 'false'
        : 'true'
    // Toggle tile image if hit
    bgSwitch = isEmpty === 'false' ? s.fill : s.empty

    // Return the new value of the hit tile
    return (grid[RowIndex(click, row)][ColumnIndex(click, column)] = (
      <button
        // Set image
        css={bgSwitch}
        // Set value
        value={grid[RowIndex(click, row)][ColumnIndex(click, column)].props.value}
        // Set occupancy status
        occupancy={isEmpty}
      ></button>
    ))
  }

  // Perform after all above !!
  useEffect(() => {
    // Game over === false
    if (endGame === false) {
      // But it was clicked..
      if (click !== -1 && click !== undefined) {
        // Refresh clicked tile
        TileRefresh()

        // But simultaneously refresh all tiles that are not outside the grid boundaries !!
        if (!(ColumnIndex(click, 1) < 0)) {
          TileRefresh(1, 0)
        }
        if (!(ColumnIndex(click, -1) >= columns)) {
          TileRefresh(-1, 0)
        }
        if (!(RowIndex(click, 1) < 0)) {
          TileRefresh(0, 1)
        }
        if (!(RowIndex(click, -1) >= rows)) {
          TileRefresh(0, -1)
        }

        // Win conditions check
        setIsWin([])
        // Check all rows
        for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
          // Check all columns
          for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
            // Add tile's occupancy status into array
            isWin.push(grid[rowIndex][columnIndex].props.occupancy)
          }
        }

        // If there is no empty tile === WIN
        if (!isWin.includes('true')) {
          setEndGame(true)
        }

        // Allow new round
        setNewRound(true)
        // Increase count of rounds
        setRoundCounter(roundCounter + 1)
      }
    }
    // Perform only after "click" change
  }, [click])

  // Perform after all above !!
  useEffect(() => {
    // Allow new initialization..
    setInit(true)
    // And create new grid..
    setGrid([])
    // If there was a change in the number columns or rows
  }, [columns, rows])

  return (
    <>
      <h1>Seal Breaker</h1>

      {/* Round counter */}
      <h3 css={s.turn}>Tah {roundCounter}</h3>

      <div css={s.settings}>
        {/* Submit has a default property to reset the page */}
        <form css={s.input}>
          <input css={s.reset} type={'submit'} value={'Reset'}></input>
        </form>

        {/* Row settings */}
        <input
          css={s.input}
          type={'number'}
          min='3'
          max='5'
          placeholder={'Počet řádků!'}
          onChange={e => setRows(e.target.value)}
        />

        {/* Column settings */}
        <input
          css={s.input}
          type={'number'}
          min='3'
          max='5'
          placeholder={'Počet sloupců!'}
          onChange={e => setColumns(e.target.value)}
        />
      </div>

      {/* Is game over ? */}
      {endGame ? <h2 css={s.center}>Gratuluji zvládl jsi to!</h2> : ''}

      {/* Game layout */}
      <table css={s.tableWrapper}>
        <tbody>
          {/* Create rows by map */}
          {grid.map((grid, index) => (
            <tr key={index}>
              {/* Create columns by map */}
              {grid.map((tile, index) => (
                // Set onClick event for cell
                <td css={s.cell} key={index} onClick={e => setClick(e.target.value)}>
                  {tile}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
