/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// Others

//Images
import mainBg from '../images/layout/mainBg.webp'
import wallpaperImg from '../images/landingPage/wallpaper.webp'

// MDI
import { mdiAccountStar } from '@mdi/js'
import { mdiArrowRightBoldBox } from '@mdi/js'
import { mdiCastEducation } from '@mdi/js'
import { mdiGit } from '@mdi/js'
import { mdiHexagonMultipleOutline } from '@mdi/js'
import { mdiPlay } from '@mdi/js'
import { mdiPuzzleStar } from '@mdi/js'
import { mdiRadioactive } from '@mdi/js'
import { mdiReact } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  wallpaper: css({
    width: `100vw`,
    height: `100vh`,
    position: `absolute`,
    left: 0,
    top: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundImage: `url(${mainBg})`,
  }),
  wallpaperImg: css({
    width: `100vw`,
    height: `100vh`,
    position: `relative`,
    borderBottom: theme.main.border.main,

    [theme.mq.minSizeCorrector]: {
      height: `150vh`,
    },
  }),
  wallpaperTitle: css({
    position: `absolute`,
    padding: `${theme.main.spacing.sm} ${theme.main.spacing.xl}`,
    margin: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    backgroundColor: theme.color.dark,
    border: theme.main.border.main,
    zIndex: 100,

    [theme.mq.mobile]: {
      padding: `0 ${theme.main.spacing.sm}`,
      margin: `${theme.main.spacing.sm} ${theme.main.spacing.xs}`,
    },
  }),
  wallpaperSubTitle: css({
    width: `50%`,
    position: `absolute`,
    right: 0,
    bottom: 0,
    padding: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    margin: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    backgroundColor: theme.color.dark,
    border: theme.main.border.main,
    zIndex: 100,

    [theme.mq.tablet]: {
      width: `auto`,
      padding: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    },

    [theme.mq.mobile]: {
      width: `auto`,
      padding: `${theme.main.spacing.xs} ${theme.main.spacing.md}`,
      margin: `${theme.main.spacing.xs} ${theme.main.spacing.xs}`,
    },

    [theme.mq.minSizeCorrector]: {
      bottom: `-50vh`,
    },
  }),
  landingPageContent: css({
    width: `${theme.responsiveSize.layout.landingPage.width}vw`,
    margin: `${theme.main.spacing.sm} ${
      (100 - theme.responsiveSize.layout.landingPage.width) / 2
    }vw`,
    padding: `${theme.main.spacing.lg} ${
      (100 - theme.responsiveSize.layout.landingPage.width) / 2
    }vw`,
    fontSize: `${theme.main.font.md}`,

    [theme.mq.tablet]: {
      width: `100%`,
      margin: 0,
      padding: `${theme.main.spacing.md} ${theme.main.spacing.sm}`,
    },
  }),
  landingPageContentCover: css({
    background: theme.color.screen,
  }),
  labelBox: css({
    display: 'flex',
    padding: theme.main.spacing.xs,
    border: theme.main.border.main,
    backgroundColor: theme.color.dark,

    [theme.mq.tablet]: {
      flexDirection: 'column',
    },
  }),
  labelMdi: css({
    margin: `0 ${theme.main.spacing.sm}`,

    [theme.mq.tablet]: {
      margin: `auto`,
    },
  }),
  reactMdi: css({
    position: `absolute`,
    top: theme.main.spacing.sm,
    right: theme.main.spacing.sm,
  }),
  titleMdiBox: css({
    margin: `0 ${theme.main.spacing.md} 0 ${theme.main.spacing.lg}`,
    [theme.mq.tablet]: {
      margin: `auto`,
    },
  }),
  labelContentBox: css({ padding: theme.main.spacing.xs }),
  labelContent: css({ textAlign: 'justify' }),
  navLink: css({
    width: `70%`,
    margin: `auto`,
    padding: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    textAlign: `center`,
    fontSize: theme.main.font.xxl,
    border: theme.main.border.main,
    backgroundColor: theme.color.dark,
    color: theme.color.discount,
    cursor: `pointer`,

    [theme.mq.tablet]: {
      width: `100%`,
      fontSize: theme.main.font.xl,
      padding: `${theme.main.spacing.md}`,
    },
  }),
  landingPageH1: css({
    margin: `${theme.main.spacing.sm} 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.xl,
    color: theme.color.secL3,

    [theme.mq.tablet]: {
      fontSize: theme.main.font.lg,
      margin: `${theme.main.spacing.md} 0`,
      textAlign: `center`,
    },
  }),
  landingPageH2: css({
    margin: `0 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.lg,
    color: theme.color.secL3,

    [theme.mq.tablet]: {
      margin: `0 0 ${theme.main.spacing.sm} 0`,
      fontSize: theme.main.font.lg,
      textAlign: `center`,
    },
  }),
  discount: css({
    position: 'absolute',
    right: theme.main.spacing.sm,
    bottom: theme.main.spacing.xxs,
    padding: theme.main.spacing.md,
    border: theme.main.border.discount,
    borderRadius: theme.main.borderRadius.round,
    fontSize: theme.main.font.lg,
    fontWeight: `bold`,
    backgroundColor: theme.color.darkBg,
    color: theme.color.discount,
    zIndex: 3000,

    [theme.mq.laptop]: {
      fontSize: theme.main.font.md,
    },

    [theme.mq.tablet]: {
      display: `none`,
    },
  }),
  discountMobile: css({
    display: `none`,
    [theme.mq.tablet]: {
      display: `block`,
      position: 'absolute',
      right: `-${theme.main.spacing.lg}`,
      bottom: theme.main.spacing.xxs,
      padding: theme.main.spacing.md,
      border: theme.main.border.discountMobile,
      borderRadius: theme.main.borderRadius.round,
      textAlign: `center`,
      fontSize: theme.main.font.lg,
      fontWeight: `bold`,
      backgroundColor: theme.color.darkBg,
      color: theme.color.discount,
      transform: `translate(0, 50%)`,
      zIndex: 3000,
    },
    [theme.mq.mobile]: {
      fontSize: theme.main.font.md,
      right: `-${theme.main.spacing.xs}`,
      padding: theme.main.spacing.sm,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HomePage = () => {
  Unit.ModulesInitialization()

  //////////////////////////// GTM ////////////////////////////
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: 'homepage',
  })
  //////////////////////////// GTM ////////////////////////////

  let currentScreenWidth = window.innerWidth
  let isMobile
  currentScreenWidth < theme.responsiveSize.layout.mobileNav.tabletSize
    ? (isMobile = true)
    : (isMobile = false)

  return (
    <>
      <Unit.AppContent>
        <div css={s.wallpaper} className='landingPage'>
          <span css={s.wallpaperTitle}>
            <Unit.AppH1>
              Dej svému skillu <Icon css={s.reactMdi} path={mdiReact} size={3} />
            </Unit.AppH1>

            <h2 css={s.landingPageH2}>
              <span css={s.titleMdiBox}>
                <Icon path={mdiArrowRightBoldBox} size={1} />
                <Icon path={mdiPlay} size={1} />
                <Icon path={mdiPlay} size={1} />
              </span>
              <strong>Frontend React&nbsp;Js</strong>
            </h2>
            <div css={s.discountMobile}>
              <strong>
                50% <br />
                sleva!
              </strong>
            </div>
          </span>
          <div css={s.discount}>
            <strong>
              Právě teď <br />
              50% sleva!
            </strong>
          </div>

          <img src={wallpaperImg} css={s.wallpaperImg} alt='Landing page wallpaper' />

          <section css={s.wallpaperSubTitle}>
            <Unit.AppH2 noTopMargin>Otevři si dveře do světa IT!</Unit.AppH2>
            <ul>
              <li>
                <strong>100% online IT kurz v oblasti softwarového vývoje!</strong>
              </li>
              <li>
                <strong>Tvorba portfolia již během kurzu!</strong>
              </li>
              <li>
                <strong>Osobní lektor k tvým službám!</strong>
              </li>
              <li>
                <strong>Práce na společném projektu!</strong>
              </li>
            </ul>
          </section>

          <section css={[s.landingPageContent, s.landingPageContentCover]}>
            <h1 css={s.landingPageH1}>S čím u nás můžeš počítat ?!</h1>

            <LabelBox mdi={mdiCastEducation} title={`100% online`}>
              Lekce jsou 100% online, lektor je vždy osobně na lekci, žádná předehraná videa!
              Nestíháš se připojit na lekci? Nevadí, vždy budeš mít k dispozici i záznam ze všech
              lekcí zpětně!
            </LabelBox>

            <LabelBox mdi={mdiAccountStar} title={`Osobní lektor k dispozici`}>
              Jedna část kurzu jsou online lekce, tam se seznámíš s daným tématem a problematikou
              všeobecně. Avšak, když se na něčem zasekneš při úkolech, dostaneš se do slepé uličky
              nebo nastane jakýkoli jiný problém, tak se prostě jednoduše spojíš s lektorem kurzu,
              který ti pomůže najít řešení.
            </LabelBox>

            <LabelBox mdi={mdiPuzzleStar} title={`Společný projekt`}>
              Zvládnout dokončit práci dle zadání v jedné osobě je jedna věc, ale jak je to s
              projekty, kde více uživatelů pracuje na tom samém? Nemáš představu jak to funguje,
              nebo si na to radši ani netroufáš? Opět nevadí! I toto si u nás vyzkoušíš!
            </LabelBox>

            <LabelBox mdi={mdiHexagonMultipleOutline} title={`Tvoje portfolio`}>
              Již v rámci kurzu si tvoříš svoje portfolio na základě zadaných úkolů z lekcí. Nejenom
              že budeš vytvářet funkční řešení k danému úkolu, naučíme tě i jak jednotlivé úkoly
              modifikovat a posunout je o další level nahoru!
            </LabelBox>

            <LabelBox mdi={mdiGit} title={`Verzovací nástroje`}>
              V rámci kurzu pracujeme s verzovacími nástroji (github, gitlab..), které jsou dnes již
              naprostou nezbytností a tobě nevyčíslitelně usnadní práci!
            </LabelBox>

            <LabelBox mdi={mdiRadioactive} title={`Garantovaná praxe!`} focus>
              Nově teď nabízíme pro absolventy našich kurzů i možnost účasti na juniorských
              projektech se započtením IT praxe! Na základě výsledků z těchto projektů i možnost
              získat práci přímo u nás (softwarový a herní vývoj) nebo našich partnerů (softwarový
              vývoj).
            </LabelBox>
          </section>
          <section css={s.landingPageContent}>
            <span onClick={isMobile ? () => '' : () => Unit.ShowNav(`Screen`, true, true)}>
              <Unit.AppLink to={'/react'}>
                <Unit.advanced.linkFocusLandingBtn css={s.navLink}>
                  Jdu na to!
                </Unit.advanced.linkFocusLandingBtn>
              </Unit.AppLink>
            </span>
          </section>
        </div>
      </Unit.AppContent>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const LabelBox = (p: {
  mdi: string
  title: string
  focus?: boolean
  children: React.ReactNode
}) => (
  <section css={[s.labelBox, p.focus ? { color: theme.color.bug } : '']}>
    <div css={s.labelMdi}>
      <Icon path={p.mdi} size={5} />
    </div>
    <div css={s.labelContentBox}>
      <h2 css={[s.landingPageH2, p.focus ? { color: theme.color.bug } : '']}>
        <strong>{p.title}</strong>
      </h2>
      <p css={s.labelContent}>{p.children}</p>
    </div>
  </section>
)
