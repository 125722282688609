/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable'
import * as theme from '../../../theme'
import { css } from '@emotion/react'

// Others
import { WingedHeader } from '../../../bricks/WingedHeader'

// Images
import course from './images/course.webp'
import degree from './images/degree.webp'

// mdi
import { mdiCogPlayOutline } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const s = {
  degreeDiv: css({
    marginTop: theme.main.spacing.lg,
    marginBottom: theme.main.spacing.md,
  }),
  degree: css({
    width: `70%`,
    position: `relative`,
    left: `50%`,
    transform: `translate(-50%, 0)`,

    [theme.mq.tablet]: {
      width: '100%',
    },
  }),
  ul: css({
    listStyle: `none`,
  }),
  legend: css({
    marginBottom: theme.main.spacing.sm,
    fontSize: theme.main.font.lg,
    textAlign: `right`,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.sm,
    },

    [theme.mq.mobile]: {
      marginTop: 0,
      fontSize: theme.main.font.md,
    },
  }),
  curriculum: css({
    width: `50%`,
    margin: `auto`,
    fontSize: theme.main.font.lg,

    [theme.mq.mobile]: {
      width: '85%',
      fontSize: theme.main.font.md,
      marginBottom: theme.main.spacing.sm,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const JavascriptCourse = () => {
  Unit.ModulesInitialization()
  GTM_ListenerInit('react')

  return (
    <>
      <WingedHeader
        img={course}
        name={'Javascript'}
        lecturer={'Matthew Antirix'}
        level={'Lehce pokročilý'}
        req={'Lze zvládnout i bez znalostí'}
        type={'100% online'}
        price={7499}
        discountPrice={3749}
        discountDate={'Sleva do 24.12.2024'}
        duration={'25 hodin + lektor na drátě'}
        basicCourse={{ id: 'A', duration: '25 hodin', paymentLink: '', discountPaymentLink: '' }}
      />

      <Unit.AppContent>
        <Unit.AppH1>
          <strong>Specifikace kurzu</strong>
        </Unit.AppH1>
        <Unit.AppH2>
          <strong>Start příštího kurzu je 8.&nbsp;ledna (&nbsp;středa 17:00 - 19:30&nbsp;)</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Online lekce probíhají každou středu v časovém rozmezí 17:00 - 19:30. Kurz obsahuje 10
          takovýchto lekcí a trvá tedy v celkovém rozsahu 10 týdnů. Rozhodně nejde o žádný pseudo
          rychlo kurz, jak z tebe vytáhnout peníze a nalít ti do hlavy veškeré informace za jediný
          víkend!. Pokud nestíháš nevadí, lekce jsou nahrávány a ptát se můžeš i mimo toto časové
          okno!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Lektor kurzu k tvým službám</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Lektor tohoto kurzu tu je pro tebe i mimo skupinové online lekce. Na každý týden máš zadán
          i úkol na zpracování. Náš lektor není žádný učitel, tak jak to znáš ze školy, ale tvůj
          kolega / parťák, tak jak tě to bude čekat i při práci v tomto oboru. To znamená, že tu
          není pouze od toho, aby si odučil své hodiny, opravil ti úkol a konec.. V žádném případě,
          mnohdy budou mít úkoly i mnoho správných řešení, nebo nepochopíš co se po tobě chce, nebo
          prostě jen jednoduše nebudeš mít vůbec tucha jak to vyřešit. Lektor je ti v tomto zcela k
          dispozici, máš na něj přímý kontakt a dle jeho časových možností s ním můžeš řešit vše co
          ti bude stát v cestě ke zvládnutí tohoto kurzu!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Pro koho je kurz určen, co potřebuji znát ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Naprosto pro kohokoliv, jde o úplný základ všeho! Doporučena alespoň základní znalost HTML
          & CSS, není však podmínkou.
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Jak kurz probíhá ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Základem jsou online lekce, kde se vždy probere dané téma a zadá se úkol na vypracování.
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Skupinový projekt ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Přesně tak, jedna věc je umět programovat, umět řešit problémy a dojít k nějakému závěru.
          Nicméně fatální nedostatky zpravidla nebývají na této úrovni, ale až při práci v týmu.
          Většina větších aplikací není reálně zvládnutelná v jedné osobě, tedy alespoň ne v
          rozumném časovém horizontu. Navíc u her toto pravidlo platí dvojnásob! V rámci projektu si
          vyzkoušíš jaké je to pracovat v týmu, jak pracovat s kódem, na kterém současně pracuje
          více osob, jak fungovat a organizovat práci v týmu. Možná si říkáš.. na tom přece nic
          není! No opak je bohužel pravdou, protože krutou realitou je, že většina projektů se
          mnohdy ani nedokončí právě kvůli nefungujícímu týmu.. v tomto kurzu nejde ani, tak o to
          jestli tvůj projekt skončí úspěšně nebo ne. Jde o to zjistit co vše to obnáší a být
          připraven / připravena na vše co může nastat.
        </Unit.AppP>

        <Unit.AppH2>Komunikační kanály</Unit.AppH2>
        <Unit.AppP>
          <ul css={s.ul}>
            <li>Online lekce: Google meets</li>
            <li>Revize úkolů: Gitlab</li>
            <li>Další komunikace: Discord</li>
          </ul>
        </Unit.AppP>

        <Unit.AppH2>Nějaké další dotazy?</Unit.AppH2>
        <Unit.AppP>
          Pokud tě zajímá i něco dalšího co ti tu chybí, klidně nám napiš na náš email:
          info@hexsquad.cz, nebo rovnou zavolej na tel. 723 584 131.
        </Unit.AppP>

        <Unit.AppH2 alignCenter>
          <strong>Rozpis lekcí dle týdnů</strong>
        </Unit.AppH2>
        <Unit.AppP>
          <ol css={s.curriculum}>
            <li>Proměnné, operátory a datové typy</li>
            <li>
              Podmínky <br />
              (if, switch, ternární operátory)
            </li>
            <li>
              Pole a cykly <br /> (for, while, do while)
            </li>
            <li>
              Události a funkce <br />
              (+ arrow funkce, map)
            </li>
            <li>
              Objektově orientované <br />
              programování - Část 1
            </li>
            <li>
              Objektově orientované <br />
              programování- Část 2
            </li>
            <li>Asynchronní JavaScript</li>

            <Unit.AppH2 alignCenter>Skupinový projekt: RPG aréna</Unit.AppH2>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Začínáme (+ git)
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Blížíme se do finále
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Prezentace projektu, <br />+ Q&A, vyhodnocení kurzu
              {/* </Unit.AppLink> */}
            </li>
          </ol>
        </Unit.AppP>

        <div css={s.degreeDiv}>
          <Unit.AppH1 alignCenter>Certifikát kurzu (Vzor)</Unit.AppH1>
          <img src={degree} css={s.degree} alt='Course degree' />
        </div>
      </Unit.AppContent>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////// GTM ////////////////////////////
const GTM_ListenerInit = (pageType: string) => {
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: pageType,
  })
}
//////////////////////////// GTM ////////////////////////////

const LessonLink = (p: { to: string; title: string }) => (
  <li>
    <span onClick={() => GTM_ListenerInit('lesson')}>
      <Unit.AppLink to={p.to} blank>
        <Icon path={mdiCogPlayOutline} size={1} /> {p.title}
      </Unit.AppLink>
    </span>
  </li>
)
