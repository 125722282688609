/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

// Images
import enemyImageSrc from './images/enemy.png'
import killImageSrc from './images/kill.png'
import playerImageSrc from './images/player.png'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  center: css({
    margin: `${theme.spacing.xs} auto`,
    textAlign: `center`,
  }),
  stance: css({
    width: `100%`,
    marginBottom: theme.spacing.xs,
    padding: theme.spacing.xxs,
    textAlign: `center`,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    cursor: `pointer`,
    backgroundColor: theme.color.secL3,
  }),
  reset: css({
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,

    [mq.tablet]: {
      marginTop: theme.spacing.sm,
    },
  }),
  battlefieldWrapper: css({
    width: `80%`,
    margin: `auto`,

    [mq.tablet]: {
      width: `100%`,
    },
  }),
  cell: css({
    width: `25%`,
    height: `150px`,
    border: `solid 2px ${theme.color.dark}`,
    backgroundColor: theme.color.lightBg,
  }),
  img: css({
    width: `100%`,
    height: `100%`,
  }),
  tableWrapper: css({
    width: `50%`,
    margin: `${theme.spacing.md} auto ${theme.spacing.lg} auto`,

    [mq.tablet]: {
      width: `100%`,
    },
  }),
  table: css({
    textAlign: `center`,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// Images settings
const playerImage = <img css={s.img} src={playerImageSrc} alt='Player' />
const enemyImage = <img css={s.img} src={enemyImageSrc} alt='Enemy' />
const killImage = <img css={s.img} src={killImageSrc} alt='Death' />

export const RockScissorsPaperGame = () => {
  // Options list [0,1,2]
  const stance = ['Agresivní postoj', 'Zákeřný postoj', 'Defenzivní postoj']
  // The player needs to make choices === -1 default OR reset, [0,1,2] are options from our list
  const [playerChoose, setPlayerChoose] = useState(-1)
  // The computer needs to make choices .. by random option from our list
  const [computerChoose, setComputerChoose] = useState(Math.floor(Math.random() * stance.length))
  // Position of the computer image on the battlefield
  const [computerStep, setComputerStep] = useState(0)
  // Enemy's HP
  const [computerHealth, setComputerHealth] = useState(2)
  // Who is the winner of this round ?
  const [winnerStatus, setWinnerStatus] = useState('')
  // Who is the winner of the game ?
  const [winner, setWinner] = useState('')
  // Is game over ?
  const [endGame, setEndGame] = useState(false)
  // Is round over ?
  const [endRound, setEndRound] = useState(false)
  // Set message after round: What has happened ?
  const [result, setResult] = useState()
  // Current battlefield layout
  const [battleField] = useState([enemyImage, '', '', playerImage])
  // Player's choice
  let playerStance = stance[playerChoose]
  // Computer's choice
  let computerStance = stance[computerChoose]

  // Only if it's not game over !!
  if (endGame === false) {
    // Only after the user's move (-1 is default, it never is user input)
    if (playerChoose !== -1) {
      // Player stance is same as Computer stance === DRAW
      if (stance.indexOf(playerStance) === stance.indexOf(computerStance)) {
        // Set round winner
        setWinnerStatus('draw')
        // Perform another game mechanic
        setComputerHealth(computerHealth + 1)
        // Reset user's input !!
        setPlayerChoose(-1)
        // Set message: What has happened ?
        setResult(
          `Nepřítel zvolil ${computerStance} proti tvé volbě ${playerStance} a vyléčil si 1 HP!`
        )
      } else if (
        // The player won over the enemy
        // const stance = ['Aggressive stance'[0] > 'Sneaky stance'[1] > 'Defensive stance'[2] > 'Aggressive stance'[0]..etc.  ] // Array limits 0-2 !!
        // player = 'Sneaky stance'[1] vs. computer = 'Defensive stance'[2] is: 1 === 2 - 1
        stance.indexOf(playerStance) === stance.indexOf(computerStance) - 1 ||
        // OR
        // player = 'Defensive stance'[2] vs. computer = 'Aggressive stance'[0] is: 2 === 0 + 2
        stance.indexOf(playerStance) === stance.indexOf(computerStance) + 2
      ) {
        // Set round winner
        setWinnerStatus('player')
        // Perform another game mechanic
        setComputerHealth(computerHealth - 1)
        // Reset user's input !!
        setPlayerChoose(-1)
        // Set message: What has happened ?
        setResult(
          `Nepřítel zvolil ${computerStance} proti tvé volbě ${playerStance} a byl zasažen tvým mečem!`
        )
      } else if (
        // The player lost to the enemy
        // const stance = ['Aggressive stance'[0] > 'Sneaky stance'[1] > 'Defensive stance'[2] > 'Aggressive stance'[0]..etc.  ] // Array limits 0-2 !!
        // computer = 'Sneaky stance'[1] vs. player = 'Defensive stance'[2] is: 1 === 2 - 1
        stance.indexOf(playerStance) === stance.indexOf(computerStance) + 1 ||
        // OR
        // computer = 'Defensive stance'[2] vs. player = 'Aggressive stance'[0] is: 2 === 0 + 2
        stance.indexOf(playerStance) === stance.indexOf(computerStance) - 2
      ) {
        // Set round winner
        setWinnerStatus('computer')
        // Reset user's input !!
        setPlayerChoose(-1)
        // Set message: What has happened ?
        setResult(
          `Nepřítel zvolil ${computerStance} proti tvé volbě ${playerStance} a přiblížil se blíže k tobě!`
        )
      }
      // Change computer's stance for next round
      setComputerChoose(Math.floor(Math.random() * stance.length))
      // End this round
      setEndRound(true)
    }

    // Enemy lost all HP
    if (computerHealth === 0) {
      // Game over === Player WIN
      setEndGame(true)
      // Change enemy's image
      battleField[computerStep] = killImage
      // Set message: What has happened ?
      setWinner('Gratuluji, zvládl jsi to!')
    }
  }

  // Perform after all above !!
  useEffect(() => {
    // If computer win round
    if (winnerStatus === 'computer') {
      // Remove enemy's image from his position
      battleField[computerStep] = ''
      // Move enemy's image closer to player
      battleField[computerStep + 1] = enemyImage
      // Save this change into useState
      setComputerStep(computerStep + 1)
      // Reset winner for next round
      setWinnerStatus('')

      // If player' image is overwritten by enemy's image === Player is dead
      if (!battleField.includes(playerImage)) {
        // Game over === Computer WIN
        setEndGame(true)
        // Set message: What has happened ?
        setWinner('Je konec, jsi mrtvý!')
      }
    }
    // Perform only after "winnerStatus" change
  }, [winnerStatus])

  return (
    <>
      <h1>Kámen, nůžky, papír</h1>

      {/* Submit has a default property to reset the page */}
      <form>
        <input css={s.reset} type={'submit'} value={'Reset'}></input>
      </form>

      {/* Is game over ? */}
      {endGame ? (
        // If true === show the winner (but hide options)
        <h2 css={s.center}>{winner}</h2>
      ) : (
        // If false === show options
        <>
          <h3 css={s.center}>Zvol svoji akci pro další kolo!</h3>
          <div>
            {/* Map our options from array */}
            {stance.map((item, index) => (
              <button
                css={s.stance}
                key={index}
                value={index}
                onClick={e => setPlayerChoose(e.target.value)}
              >
                {item}
              </button>
            ))}
          </div>
        </>
      )}

      {/* Is round over? If true === show result message*/}
      {endRound ? <h3 css={s.center}>{result}</h3> : ''}

      {/* Battlefield layout */}
      <table css={s.battlefieldWrapper}>
        <tbody>
          <tr>
            <th>Zdraví oponenta: {computerHealth}</th>
          </tr>
          <tr>
            {/* Map our current battlefield layout */}
            {battleField.map((item, index) => (
              <td css={s.cell} key={index}>
                {item}
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      {/* Tips how to win*/}
      <table css={s.tableWrapper}>
        <thead css={s.table}>
          <tr>
            <th>Výhra</th>
            <th>Prohra</th>
          </tr>
        </thead>
        <tbody css={s.table}>
          <tr>
            <td>Agresivní postoj</td>
            <td>Zákeřný postoj</td>
          </tr>
          <tr>
            <td>Zákeřný postoj</td>
            <td>Defenzivní postoj</td>
          </tr>
          <tr>
            <td>Defenzivní postoj</td>
            <td>Agresivní postoj</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
