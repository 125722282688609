/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'

// Images
import dragonImageSrc from './images/dragon.png'
import knightImageSrc from './images/knight.png'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  center: css({
    margin: `${theme.spacing.xs} auto`,
    textAlign: `center`,
  }),
  reset: css({
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,

    [mq.tablet]: {
      marginTop: theme.spacing.sm,
    },
  }),
  tableWrapper: css({
    width: `100%`,
  }),
  playerCell: css({
    width: `25%`,
    padding: theme.spacing.xs,
    textAlign: `center`,
    backgroundColor: theme.color.secL2,

    [mq.tablet]: {
      display: `none`,
    },
  }),
  gameWrapper: css({
    width: `100%`,
    display: `flex`,
    justifyContent: `center`,
  }),
  cell: css({
    width: `100px`,
    height: `100px`,
    border: `solid 1px ${theme.color.darkBg}`,
    backgroundColor: theme.color.lightBg,
  }),
  cellBtn: css({
    width: `100%`,
    height: `100%`,
    border: `hidden`,
    color: `transparent`,
  }),
  img: css({
    width: `95px`,
    height: `95px`,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

// Images settings
const dragonImage = <img css={s.img} key={0} src={dragonImageSrc} alt='Dragon' />
const knightImage = <img css={s.img} key={1} src={knightImageSrc} alt='Knight' />

export const TicTacToe = () => {
  // We need starting grid for game, but we don't want to reset this value after each round !!
  const [init, setInit] = useState(true)
  // The player needs to make choices === -1 default OR reset
  const [click, setClick] = useState(-1)
  // Is round over ?
  const [newRound, setNewRound] = useState(false)
  // Who's turn ?
  const [activeFirstPlayer, setActiveFirstPlayer] = useState(true)
  // Current game layout, what we see in the game
  const [array] = useState([])
  // Hidden game layout for checking win conditions
  const [winArray, setWinArray] = useState([])
  // Is game over ?
  const [endGame, setEndGame] = useState(false)
  // Is draw ?
  const [isDraw, setIsDraw] = useState(false)

  // Set variables for initialization
  let row = []
  let square,
    // The cellIndex is subject to global scope
    cellIndex = 0

  // Game layout initialization, do it only once !!
  if (init === true) {
    // Create grid
    const CreateGrid = () => {
      // The first "for" represents rows
      for (let i = 0; i < 3; i++) {
        // The second "for" represents specific cells in rows (in another words === columns)
        for (let x = 0; x < 3; x++) {
          // Square is specific "single" cell
          square = (
            <button css={s.cellBtn} value={cellIndex} key={x}>
              {cellIndex}
            </button>
          )
          // Add last square / cell into row
          row.push(square)
          // Increase next cell number by 1, the global scope is not reset between cycles !!
          cellIndex++
        }
        // Add last created row into array / grid
        array.push(row)
        // Reset row for next round
        row = []
      }
    }
    // Deactivate initialization, only once is needed !!
    setInit(false)
    // Call function CreateGrid() for creating grid
    CreateGrid()
  }

  // Next round check, next round is after user's click ..
  // -1 is default for initialization or reset after round, it's not user's click
  // "undefined" check is a safeguard against an unexpected fatal error
  if (click !== -1 && click !== undefined) {
    // If it was clicked and there is a new round at the same time, prevents the endless loop of useEffect, waiting for the next user's click !!
    if (newRound === true) {
      // Switch active player's turn
      setActiveFirstPlayer(!activeFirstPlayer)
      // Reset new round status
      setNewRound(!newRound)
      // Reset user's click status
      setClick(-1)
    }
  }

  // End Game check
  const winCheck = grid => {
    // There are 8 different win combinations, we have to know all of them !!
    const winLines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ]

    // Check all win combinations [0-7]
    for (let i = 0; i < winLines.length; i++) {
      // Set all values from indexes
      const [a, b, c] = winLines[i]
      // If all indexes are equal..
      if (grid[a] && grid[a] === grid[b] && grid[a] === grid[c]) {
        // Then it's game over
        setEndGame(true)
        // Return value from any cell (value is image, which identify winner)
        return grid[a]
      }
    }
    // If the victory conditions are not met and there are no other free squares at the same time
    if (!winArray.includes('')) {
      // Then it's game over
      setEndGame(true)
      // It's DRAW
      setIsDraw(true)
    }
    return null
  }

  // Row index function
  // If cell index of user' click is 5, then 5 / 3 = 1,66.. rounded down it is 1 .. this result is index of our clicked row
  const RowIndex = id => {
    return Math.floor(id / 3)
  }

  // Column index function
  // If cell index of user' click is 5, then 5 % 3 = 2 .. this result is index of our clicked column
  const ColumnIndex = id => {
    return id % 3
  }

  // Grid render function
  const GridRender = player => {
    // Set player's image on clicked position
    array[RowIndex(click)][ColumnIndex(click)] = <div>{player}</div>
  }

  // Perform after all above !!
  useEffect(() => {
    // Game over === false
    if (endGame === false) {
      // But it was clicked..
      if (click !== -1 && click !== undefined) {
        // Set dragon image on dragon's turn
        if (activeFirstPlayer) {
          GridRender(dragonImage)
          // Set knight image on knight's turn
        } else if (!activeFirstPlayer) {
          GridRender(knightImage)
        }

        // Win conditions check
        setWinArray([])
        // Check all rows
        for (let row = 0; row < 3; row++) {
          // Check all columns
          for (let column = 0; column < 3; column++) {
            // If the target value is not "undefined"
            if (array[row][column].props.children.props !== undefined) {
              // Push alt of target value into win array (alt is dragon or knight)
              winArray.push(array[row][column].props.children.props.alt)
            } else {
              // If the target value is "undefined" then push '' into win array
              winArray.push('')
            }
          }
        }
        // Allow new round
        setNewRound(true)
        // Refresh and save current grid data
        winCheck(winArray)
      }
    }
    // Perform only after "click" change
  }, [click])

  return (
    <>
      <h1>Piškvorky</h1>

      {/* Submit has a default property to reset the page */}
      <form>
        <input css={s.reset} type={'submit'} value={'Reset'}></input>
      </form>

      {/* Is game over ? */}
      {endGame ? (
        // Game over === true, Is it draw?
        isDraw ? (
          // Draw === true
          <h2 css={s.center}>Remíza! </h2>
        ) : (
          // Draw === false, winner is dragon or knight ?
          <h2 css={s.center}>Vítězem je {activeFirstPlayer ? 'rytíř' : 'drak'}!</h2>
        )
      ) : (
        // Game over === false, who's turn ?
        <h2 css={s.center}>Na tahu je {activeFirstPlayer ? 'drak' : 'rytíř'}</h2>
      )}

      {/* Game layout */}
      <table css={s.tableWrapper}>
        <tbody css={s.table}>
          <tr>
            {/* Dragon's turn ? */}
            <td css={s.playerCell}>{activeFirstPlayer ? [dragonImage] : ''}</td>
            {/* 3x3 game grid */}
            <td css={s.gameWrapper}>
              <table>
                <tbody>
                  {/* Create rows by map */}
                  {array.map((item, index) => (
                    <tr key={index}>
                      {/* Create columns by map */}
                      {item.map((cell, index) => (
                        // Set onClick event for cell
                        <td css={s.cell} key={index} onClick={e => setClick(e.target.value)}>
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
            {/* Knight's turn ? */}
            <td css={s.playerCell}>{activeFirstPlayer ? '' : [knightImage]}</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}
