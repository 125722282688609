import { css } from '@emotion/react'

export const mq = {
  // Mobile – 360 x 640; 375 x 667; 360 x 720.
  // iPhone – 375 x 812.
  // Tablet – 768 x 1024.
  // Laptop – 1366 x 768.
  // Desktop or high-resolution laptop – 1920 x 1080.
  mobile: `@media (max-width: 500px)`,
  tabletSm: `@media (max-width: 650px)`,
  tablet: `@media (max-width: 850px)`,
  laptopSm: `@media (max-width: 1100px)`,
  laptop: `@media (max-width: 1500px)`,
  desktop: `@media (max-width: 2000px)`,
  outOfLimit: `@media (min-width: 2001px)`,

  minWidthCorrector: `@media (max-width: 400px)`,
  minSizeCorrector: `@media (max-width: 400px) and (max-height: 600px)`,
} as const

export const responsiveSize = {
  layout: {
    mainWrapper: {
      width: 100, //vw
      height: 100, //vh
    },
    mobileNav: {
      height: 5, //vh
      tabletSize: 850, // Boundaries for nav fix
    },
    leftNav: {
      width: 15, //vw
    },
    rightNav: {
      width: 25, //vw
    },
    screen: {
      width: 60, //vw
      widthMobile: 60, //vw
      widthMobileWider: 80, //vw
    },
    landingPage: {
      width: 80, //vw
    },
    headerImg: {
      height: 30, //vh
    },
    profileWingStep: {
      width: 2, //vw
      height: 1, //vw (really in vw !!!)
    },
  },
  font: {
    xs: 0.65, //rem
    sm: 0.85, //rem
    md: 1, //rem
    mdContent: 1, //rem
    lg: 1.7, //rem
    xl: 2.5, //rem
    xxl: 4, //rem
  },
  spacing: {
    xxs: 0.3, //rem
    xs: 0.5, //rem
    sm: 1, //rem
    md: 2, //rem
    lg: 4, //rem
    xl: 6, //rem
  },
  borderRadius: {
    contentBox: 20, //px
    profileWing: 15, //px
    profileImg: 15, //px
    round: 100, //%
  },
} as const

type stringArrayIndex = { [index: string]: string }
export const color: stringArrayIndex = {
  light: '#fff',
  lightBg: '#fff',

  dark: '#000',
  darkBg: '#000',

  main: '#0f0',
  secL1: `#3f3`,
  secL2: `#6f6`,
  secL3: `#9f9`,
  secL4: `#cfc`,
  secD1: `#0c0`,
  secD2: `#090`,
  secD3: `#060`,
  secD4: `#030`,

  link: `#fff`,
  bug: `#f00`,
  discount: `#00d0ff`,

  screen: `rgba(0,0,0, 0.8)`,
  navBar: `rgba(0,0,0, 0.8)`,
  wing: `rgba(0,255,0, 0.2)`,
  profileHover: `rgba(0,255,0, 0.1)`,
  landingBtn: `rgba(0,0,0)`,
  cookies: `rgba(255,0,0, 0.4)`,
  contact: `rgba(0,255,0, 0.4)`,
}

export const main = {
  font: {
    xs: `${responsiveSize.font.xs}rem`,
    sm: `${responsiveSize.font.sm}rem`,
    md: `${responsiveSize.font.md}rem`,
    mdContent: `${responsiveSize.font.mdContent}rem`,
    lg: `${responsiveSize.font.lg}rem`,
    xl: `${responsiveSize.font.xl}rem`,
    xxl: `${responsiveSize.font.xxl}rem`,
  },
  spacing: {
    xxs: `${responsiveSize.spacing.xxs}rem`,
    xs: `${responsiveSize.spacing.xs}rem`,
    sm: `${responsiveSize.spacing.sm}rem`,
    md: `${responsiveSize.spacing.md}rem`,
    lg: `${responsiveSize.spacing.lg}rem`,
    xl: `${responsiveSize.spacing.xl}rem`,
  },
  border: {
    main: `2px solid ${color.main}`,
    bug: `2px solid ${color.bug}`,
    discount: `5px solid ${color.discount}`,
    discountMobile: `2px solid ${color.discount}`,
  },
  borderRadius: {
    contentBox: `${responsiveSize.borderRadius.contentBox}px`,
    profileWing: `${responsiveSize.borderRadius.profileWing}px`,
    profileImg: `${responsiveSize.borderRadius.profileImg}px`,
    round: `${responsiveSize.borderRadius.round}%`,
  },
  shadow: {
    text: {
      main: `-2px -2px 2px ${color.dark}, 2px -2px 2px ${color.dark}, -2px 2px 2px ${color.dark}, 2px 2px 2px ${color.dark}`,
    },
    box: {
      main: `0px 0px 10px ${color.main}`,
    },
  },
} as const

export const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  #orderSubmit:hover {
    color: ${color.light};
  }

  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 2px solid ${color.main};
  }
  .landingPage::-webkit-scrollbar {
    width: 0px;
  }
`
