/** @jsxImportSource @emotion/react */
// Default Reusable
import * as theme from './theme'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

// Others
import { Link } from 'react-router-dom'
import TagManager from 'react-gtm-module'

// Images
import link from './images/layout/link.webp'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const main = {
  alignCenter: css({
    textAlign: `center`,
  }),
  alignRight: css({
    textAlign: `right`,
  }),
  extraTopMargin: css({
    marginTop: theme.main.spacing.lg,
  }),
  extraBottomMargin: css({
    marginBottom: theme.main.spacing.xl,
  }),
  noTopMargin: css({
    marginTop: 0,
  }),
  italic: css({
    fontStyle: `italic`,
  }),
  appContent: css({
    margin: `${theme.main.spacing.sm} 0`,
    fontSize: theme.main.font.md,

    [theme.mq.tablet]: {
      margin: `${theme.main.spacing.md} 0`,
    },

    [theme.mq.mobile]: {
      margin: `${theme.main.spacing.sm} 0`,
    },
  }),
  appP: css({
    marginBottom: theme.main.spacing.sm,
    textAlign: `justify`,
    fontSize: theme.main.font.mdContent,
  }),
  appH1: css({
    margin: `${theme.main.spacing.sm} 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.xl,
    color: theme.color.secL3,
  }),
  appH2: css({
    margin: `${theme.main.spacing.md} 0 ${theme.main.spacing.xxs} 0`,
    fontSize: theme.main.font.lg,
    color: theme.color.secL3,
  }),
  appQuotation: css({
    margin: `${theme.main.spacing.md} ${theme.main.spacing.xl}`,
    fontSize: theme.main.font.lg,
    fontStyle: 'italic',

    [theme.mq.tablet]: {
      margin: `${theme.main.spacing.md} ${theme.main.spacing.sm}`,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS - Advanced
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const advanced = {
  headerImg: css({
    width: '100%',
    height: `${theme.responsiveSize.layout.headerImg.height}vh`,
  }),
  link: css({
    textDecoration: `none`,
    color: theme.color.main,
  }),
  linkFocus: css({
    backgroundImage: `url(${link})`,
    backgroundSize: `100% 100%`,
    margin: `${theme.main.spacing.xs} 0`,
    padding: `${theme.main.spacing.xxs} ${theme.main.spacing.xs}`,
    textAlign: 'center',
    fontSize: theme.main.font.lg,
    cursor: 'pointer',
    border: theme.main.border.main,
    boxShadow: theme.main.shadow.box.main,
    textShadow: theme.main.shadow.text.main,

    [theme.mq.tablet]: {
      width: '60vw',
      margin: `${theme.main.spacing.xs} auto`,
    },
  }),
  linkFocusHover: styled.div`
    &:hover {
      color: ${theme.color.link};
    }
  `,
  linkFocusHoverSpan: styled.span`
    &:hover {
      color: ${theme.color.link};
    }
  `,
  linkFocusBox: styled.div`
    &:hover {
      color: ${theme.color.link};
      background-color: ${theme.color.profileHover};
    }
  `,
  linkFocusLandingBtn: styled.div`
    &:hover {
      color: ${theme.color.link};
      background-color: ${theme.color.landingBtn};
    }
  `,
  checkboxOptionBox: css({
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  checkboxBtn: css({
    width: `20px`,
    height: `20px`,
    marginRight: theme.main.spacing.xs,
  }),
  checkboxBtnOn: css({
    backgroundColor: theme.color.main,
  }),
  checkboxBtnOff: css({
    backgroundColor: theme.color.bug,
  }),
  miniText: css({
    fontSize: theme.main.font.sm,
    fontWeight: `normal`,
    margin: `0 auto ${theme.main.spacing.sm} ${theme.main.spacing.md}`,
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type PropsMain = {
  children: React.ReactNode
  alignCenter?: boolean
  alignRight?: boolean
  extraTopMargin?: boolean
  extraBottomMargin?: boolean
  noTopMargin?: boolean
  italic?: boolean
  bug?: boolean
}
export const AppContent = (p: PropsMain) => <article css={main.appContent}>{p.children}</article>
export const AppP = (p: PropsMain) => (
  <section
    css={[
      main.appP,
      p.alignCenter ? main.alignCenter : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.extraBottomMargin ? main.extraBottomMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </section>
)
export const AppH1 = (p: PropsMain) => (
  <h1
    css={[
      main.appH1,
      p.alignCenter ? main.alignCenter : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </h1>
)
export const AppH2 = (p: PropsMain) => (
  <h2
    css={[
      main.appH2,
      p.alignCenter ? main.alignCenter : '',
      p.alignRight ? main.alignRight : '',
      p.extraTopMargin ? main.extraTopMargin : '',
      p.noTopMargin ? main.noTopMargin : '',
      p.italic ? main.italic : '',
    ]}
  >
    {p.children}
  </h2>
)

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Advanced Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const AppCheckbox = (p: { title: String; miniText?: String[]; isChecked: boolean }) => {
  return (
    <span css={advanced.checkboxOptionBox}>
      <button
        css={[advanced.checkboxBtn, p.isChecked ? advanced.checkboxBtnOn : advanced.checkboxBtnOff]}
      />
      {p.title}
      {p.miniText ? (
        <ul css={advanced.miniText}>
          {p.miniText.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </span>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Link Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

type PropsLink = { children: React.ReactNode; to: string; blank?: boolean; bug?: boolean }
export const AppLink = (p: PropsLink) => {
  let blank = p.blank ? '_blank' : ''
  return (
    <Link to={p.to} target={blank} css={advanced.link}>
      {p.children}
    </Link>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Modules & functions
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ModulesInitialization = () => {
  GTM_Init()
  let audioInitialization
  ScreenScrollReset()
  if (!audioInitialization) {
    AudioAutoplay()
  }
}

export const GTM_Init = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-WXBX57XH',
  }
  TagManager.initialize(tagManagerArgs)
}

export const ScreenScrollReset = () => {
  let getScreen = document.getElementById('Screen')
  !getScreen ? '' : getScreen.scrollTo({ top: 0, behavior: 'smooth' })
}

export const GetCookies = (name: string) => {
  //@ts-ignore
  return JSON.parse(localStorage.getItem(name))
}

export const AudioAutoplay = () => {
  let isAudio = document.getElementById('Audio')
  let muteBtn = document.getElementById('MuteBtn')
  let isInit = false
  let isAudioAllow = GetCookies('isAudioAllow') === true && GetCookies('isAudioPause') !== true

  GetCookies('Cookies') === `allowAll`
    ? localStorage.setItem('isAudioAllow', JSON.stringify(true))
    : localStorage.setItem('isAudioAllow', JSON.stringify(false))

  //@ts-ignore
  !muteBtn
    ? ''
    : //@ts-ignore
    document.getElementById('MuteBtn').style.display === 'inline'
    ? (isInit = true)
    : ''
  //@ts-ignore
  if (!isInit) {
    if (isAudioAllow) {
      //@ts-ignore
      !isAudio ? '' : isAudio.play()
    }
  }
}

export const AudioMute = (isMuted: boolean) => {
  let isAudio = document.getElementById('Audio')

  if (!isMuted) {
    //@ts-ignore
    !isAudio ? '' : isAudio.pause()
    isMuted = !isMuted
  } else {
    //@ts-ignore
    !isAudio ? '' : isAudio.play()
    isMuted = !isMuted
  }
}

export const ShowNav = (navID: string, isOn: Boolean, isDesktop?: Boolean) => {
  let findNewScreen = document.getElementById(navID)
  let findToolNav = document.getElementById('ToolNav')
  let findBriefingNav = document.getElementById('BriefingNav')
  let findScreen = document.getElementById('Screen')
  let isToolNavActive = false

  let allScreens = [findToolNav, findBriefingNav, findScreen]

  if (isDesktop && findToolNav && findBriefingNav && findScreen) {
    if (window.location.pathname === '/') {
      findToolNav.style.display = 'none'
      findBriefingNav.style.display = 'none'
      findScreen.style.width = `${theme.responsiveSize.layout.landingPage.width}vw`
      findScreen.style.margin = `0 ${(100 - theme.responsiveSize.layout.landingPage.width) / 2}vw`
      findScreen.style.padding = `0 ${(100 - theme.responsiveSize.layout.landingPage.width) / 2}vw`
    } else {
      findToolNav.style.display = 'inline-block'
      findBriefingNav.style.display = 'inline-block'
      findScreen.style.width = `${theme.responsiveSize.layout.screen.width}vw`
      findScreen.style.margin = `0 ${theme.responsiveSize.layout.rightNav.width}vw 0 ${theme.responsiveSize.layout.leftNav.width}vw`
      findScreen.style.padding = theme.main.spacing.sm
    }
  }

  if (!isDesktop) {
    if (navID === 'ToolNav' && isOn && findToolNav?.style.display !== 'inline-block') {
      isToolNavActive = true
    }

    const HideAllScreens = () => {
      allScreens.map(screen => {
        //@ts-ignore
        screen.style.display = 'none'
      })
    }

    if (!isOn || isToolNavActive) {
      if (findToolNav && findBriefingNav && findScreen) {
        HideAllScreens()
        //@ts-ignore
        findNewScreen.style.display = 'inline-block'
      }
    } else {
      HideAllScreens()
      //@ts-ignore
      findScreen.style.display = 'inline-block'
    }
  }
}
