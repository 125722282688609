/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tabletResponsive: 850,
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  mainWrapper: css({
    marginBottom: theme.spacing.lg,
  }),
  center: css({
    margin: `${theme.spacing.xs} auto`,
    textAlign: `center`,
  }),
  reset: css({
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,

    [mq.tablet]: {
      marginTop: theme.spacing.sm,
    },
  }),
  start: css({
    padding: `0 ${theme.spacing.xs}`,
    margin: `${theme.spacing.xs} 0`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.lg,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  tableWrapper: css({
    margin: `${theme.spacing.md} auto`,
    backgroundColor: theme.color.secD2,
  }),
  cell: css({
    width: `50px`,
    height: `50px`,
  }),
  empty: css({
    width: `100%`,
    height: `100%`,
    backgroundColor: theme.color.secD4,
  }),
  controlsBox: css({
    textAlign: `center`,
  }),
  controlsKey: css({
    margin: theme.spacing.xxs,
    padding: `${theme.spacing.xxs} ${theme.spacing.xs}`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.lg,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Snake = () => {
  // Display width checker for mobile controls
  const windowWidth = useRef(window.innerWidth)

  // We need starting grid for game, but we don't want to reset this value after each round !!
  const [init, setInit] = useState(true)
  // Game is paused, if not switched to the keyboard !!
  const [isPause, setIsPause] = useState(true)
  // Is game over ?
  const [endGame, setEndGame] = useState(false)
  // Is win ?
  const [gameWin, setGameWin] = useState(false)

  // Where is the snake head ?
  const [snakeHead, setSnakeHead] = useState('')
  // Where is the snake body ?
  const [snakeBody, setSnakeBody] = useState([])

  // We need to know last movement
  const [previousMove, setPreviousMove] = useState([])

  // Current game layout, what we see in the game
  const [grid, setGrid] = useState([])
  const [rows, setRows] = useState(7)
  const [columns, setColumns] = useState(7)

  // Variables for our grid
  let newRow = [],
    newTile,
    // The tileIndex is subject to global scope
    tileIndex = 0

  // Column index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 % 3 = 2 .. this result is index of our clicked column
  const GetColumnIndex = id => {
    return id % columns
  }

  // Auxiliary switch for first play
  const GameStart = () => {
    setIsPause(false)
  }

  // Tile color changer
  const ChangeTileColor = (id, color) => {
    document.getElementById(id).style.backgroundColor = color
  }

  // Spawn a new food
  const CreateFood = () => {
    // Choose a random number from existing tile range, rounded down..
    let newFood = Math.floor(Math.random() * (columns * rows))
    // If the tile number is selected where the snake is located ..
    if (snakeBody.includes(newFood)) {
      // If there is no other free tile === End Game / WIN
      if (columns * rows === snakeBody.length) {
        setEndGame(true)
        setGameWin(true)
      } // If there is other free tile, choose another number..
      else {
        CreateFood()
      } // If you have empty tile === change color for food type
    } else {
      ChangeTileColor(newFood, 'black')
    }
  }

  // Possible snake moves
  const PossibleMove = shift => {
    // Expand the body of the snake about the tile to which the snake moved (unshift === first position in array (head) !!)
    snakeBody.unshift(snakeHead + shift)
    // If the snake moved to the empty tile / no food
    if (document.getElementById(snakeHead + shift).style.backgroundColor !== 'black') {
      // Change the color of the snake tail on the empty tile (last index in array (tail) !!)
      ChangeTileColor(snakeBody[snakeBody.length - 1], theme.color.secD4)
      // Remove last index from snake body (tail)
      snakeBody.pop()
    } else {
      // If food was eaten, create a new one.. but at the same time do not shorten the length of the snake body !!
      CreateFood()
    }
    // If the snake moved to the tile with own body .. === tile with snake color
    if (document.getElementById(snakeHead + shift).style.backgroundColor === `rgb(0, 255, 0)`) {
      // It's game over, change snake head to red..
      ChangeTileColor(snakeHead + shift, 'red')
      setEndGame(true)
      // If the snake moved to the empty tile / no collision
    } else {
      // Change tile with snake head to green..
      ChangeTileColor(snakeHead + shift, theme.color.main)
      // and set a new snake head position
      setSnakeHead(snakeHead + shift)
    }
  }

  // Check submit and update list //
  const CheckSubmit = e => {
    // Case sensitive fix ('A' === 'a')
    // e.key === keyboard input ... e === mobile buttons input
    e.key ? (e = e.key.toLowerCase()) : (e = e.toLowerCase())

    // If is not game over
    if (!endGame) {
      // "e" is a pressed key if it is "a" or "arrowleft"..
      if (e === 'a' || e === 'arrowleft') {
        // Prevents the movement of the head of the snake back !!
        if (previousMove !== 'd' && previousMove !== 'arrowright') {
          // Save last movement
          setPreviousMove(e)
          // and the following tile is not existing in grid range === you hit the wall !!
          if (GetColumnIndex(snakeHead - 1) > GetColumnIndex(snakeHead) || snakeHead - 1 < 0) {
            // Change snake head color to red, and Game over !!
            ChangeTileColor(snakeHead, 'red')
            setEndGame(true)
          } else {
            // If it is possible move, then continue on PossibleMove()
            PossibleMove(-1)
          }
        }
      }
      // "e" is a pressed key if it is "d" or "arrowright"..
      if (e === 'd' || e === 'arrowright') {
        // Prevents the movement of the head of the snake back !!
        if (previousMove !== 'a' && previousMove !== 'arrowleft') {
          // Save last movement
          setPreviousMove(e)
          // and the following tile is not existing in grid range === you hit the wall !!
          if (GetColumnIndex(snakeHead + 1) < GetColumnIndex(snakeHead)) {
            // Change snake head color to red, and Game over !!
            ChangeTileColor(snakeHead, 'red')
            setEndGame(true)
          } else {
            // If it is possible move, then continue on PossibleMove()
            PossibleMove(1)
          }
        }
      }
      // "e" is a pressed key if it is "w" or "arrowup"..
      if (e === 'w' || e === 'arrowup') {
        // Prevents the movement of the head of the snake back !!
        if (previousMove !== 's' && previousMove !== 'arrowdown') {
          // Save last movement
          setPreviousMove(e)
          // and the following tile is not existing in grid range === you hit the wall !!
          if (snakeHead - columns < 0) {
            // Change snake head color to red, and Game over !!
            ChangeTileColor(snakeHead, 'red')
            setEndGame(true)
          } else {
            // If it is possible move, then continue on PossibleMove()
            PossibleMove(-columns)
          }
        }
      }
      // "e" is a pressed key if it is "s" or "arrowdown"..
      if (e === 's' || e === 'arrowdown') {
        // Prevents the movement of the head of the snake back !!
        if (previousMove !== 'w' && previousMove !== 'arrowup') {
          // Save last movement
          setPreviousMove(e)
          // and the following tile is not existing in grid range === you hit the wall !!
          if (snakeHead + columns > columns * rows - 1) {
            // Change snake head color to red, and Game over !!
            ChangeTileColor(snakeHead, 'red')
            setEndGame(true)
          } else {
            // If it is possible move, then continue on PossibleMove()
            PossibleMove(columns)
          }
        }
      }
    }
  }

  // Game layout initialization, do it only once !!
  if (init === true) {
    // Create grid
    const CreateGrid = () => {
      // The first "for" represents rows
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        // The second "for" represents specific cells in rows (in another words === columns)
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          // Tile is specific "single" cell
          newTile = (
            <button css={s.empty} value={tileIndex} key={columnIndex} id={tileIndex}></button>
          )
          // Add last tile into row
          newRow.push(newTile)
          // Increase next tile number by 1, the global scope is not reset between cycles !!
          tileIndex++
        }
        // Add last created row into array / grid
        grid.push(newRow)
        // Reset row for next round
        newRow = []
      }
    }

    // Deactivate initialization, only once is needed !!
    setInit(false)
    // Call function CreateGrid() for creating grid
    CreateGrid()
  }

  // Create snake
  const CreateSnake = () => {
    // Where is the snake head ?
    let snakeHeadInit

    // The number of tiles is even
    if ((rows * columns) % 2 === 0) {
      // We choose some tile around the center
      snakeHeadInit = Math.floor((rows * columns) / 2 - columns / 2)
      // And change color of this tile to green
      ChangeTileColor(snakeHeadInit, theme.color.main)
    } else {
      // The number of tiles is odd.. We select the exact center
      snakeHeadInit = Math.floor((rows * columns - 1) / 2)
      // And change color of this tile to green
      ChangeTileColor(snakeHeadInit, theme.color.main)
    }
    // Set snake head
    setSnakeHead(snakeHeadInit)
    // And add his head to the snake body
    snakeBody.push(snakeHeadInit)

    // At the same time, create the first food
    CreateFood()
  }

  // Perform after all above !!
  useEffect(() => {
    // We can create snake only if we have existing grid, we need wait for this by useEffect
    CreateSnake()
    // Do it only once
  }, [])

  return (
    <div css={s.mainWrapper}>
      <h1>Had</h1>

      {/* Submit has a default property to reset the page */}
      <form>
        <input css={s.reset} type={'submit'} value={'Reset'}></input>
      </form>

      {/* If mobile responsive, then hide this controls.. */}
      {windowWidth.current < mq.tabletResponsive ? (
        ''
      ) : (
        // If no, then show this controls..
        <div css={s.center}>
          {/* Score is the number of eaten food === body length - head */}
          <h2>Score: {snakeBody.length - 1}</h2>
          {/* Start button === click is event for GameStart and onKeyDown element */}
          <button css={s.start} onKeyDown={CheckSubmit} onClick={GameStart}>
            {/* If is game over / Win === change text */}
            {gameWin ? '!!! Vyhrál jsi !!!' : 'Přepnou ovládání'}
          </button>
          <p>
            {/* If is onKeyDown listener active === after pressing the button, show movement instructions */}
            {isPause
              ? 'Stiskni tlačítko pro přepnutí myši na klávesnici!'
              : 'Pohyb "WASD" nebo pomocí šipek '}
          </p>
        </div>
      )}

      {/* Game layout */}
      <table css={s.tableWrapper}>
        <tbody>
          {/* Create rows by map */}
          {grid.map((grid, index) => (
            <tr key={index}>
              {/* Create columns by map */}
              {grid.map((tile, index) => (
                // Create a cell
                <td css={s.cell} key={index}>
                  {tile}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Button controls for mobile devices */}
      {windowWidth.current < mq.tabletResponsive ? (
        gameWin ? (
          <div css={s.center}>!!! Vyhrál jsi !!!</div>
        ) : (
          <div css={s.controlsBox}>
            <button css={s.controlsKey} onClick={() => CheckSubmit('W')}>
              W
            </button>
            <br />
            <button css={s.controlsKey} onClick={() => CheckSubmit('A')}>
              A
            </button>
            <button css={s.controlsKey} onClick={() => CheckSubmit('S')}>
              S
            </button>
            <button css={s.controlsKey} onClick={() => CheckSubmit('D')}>
              D
            </button>
          </div>
        )
      ) : (
        ''
      )}
    </div>
  )
}
