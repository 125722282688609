export const briefingLog = [
  // index 0
  `Vítej na našem oficiálním webu,
  jsem tvůj Hex bot!

  V tomto logu ti mohu dát tipy a rady, nebo tě seznámit s našimi nejaktuálnějšími novinkami!`,

  // index 1
  `Nevyhovuje ti časový rozpis nebo intenzita našich aktuálních kurzů?

  Máš specifické požadavky na náplň kurzu nebo potřebuješ rozšířit jeho obsah tobě na míru?

  Stačí nás kontaktovat.
  Na všem se dá domluvit a pokud to bude v našich silách jsme ochotni ti vyjít vstříc.`,

  // index 2
  `Máš vlastní partu, se kterou se chceš zapsat na kurz za výhodnějších podmínek?

  Opět žádný problém, stačí nás kontaktovat a domluvit se na nabídce přímo tobě na míru!`,
]
