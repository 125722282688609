/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'

// Images
import blackPawn from './images/black_pawn.png'
import blackQueen from './images/black_queen.png'
import whitePawn from './images/white_pawn.png'
import whiteQueen from './images/white_queen.png'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,

    move: `green`,
    error: `red`,
    jump: `blue`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  mobile: `@media (max-width: 500px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  reset: css({
    marginTop: theme.spacing.sm,
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  p: css({ textAlign: `justify` }),
  tableWrapper: css({
    margin: `${theme.spacing.md} auto ${theme.spacing.lg} auto`,
  }),
  img: css({
    width: `50px`,
    height: `50px`,

    [mq.mobile]: {
      width: `30px`,
      height: `30px`,
    },
  }),
  cell: css({
    width: `50px`,
    height: `50px`,

    [mq.mobile]: {
      width: `30px`,
      height: `30px`,
    },
  }),
  tileBtn: css({
    width: `100%`,
    height: `100%`,
    cursor: `pointer`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `50%`,
    backgroundPosition: `center`,
  }),
  winTile: css({
    backgroundColor: theme.color.main,
  }),
  whiteTile: css({
    backgroundColor: theme.color.lightBg,
  }),
  blackTile: css({
    backgroundColor: theme.color.darkBg,
  }),
  whitePawn: css({
    backgroundImage: `url(${whitePawn})`,
  }),
  blackPawn: css({
    backgroundImage: `url(${blackPawn})`,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Checkers = () => {
  // Initialization settings
  // We need starting grid for game, but we don't want to reset this value after each round !!
  const [init, setInit] = useState(true)
  // Current game layout, what we see in the game
  const [grid, setGrid] = useState([])

  // Variables for our grid
  let newRow = [],
    rows = 8,
    columns = 8,
    newTile,
    // The tileIndex is subject to global scope
    tileIndex = 0

  // Who's turn ?
  const [activeFirstPlayer, setActiveFirstPlayer] = useState(true)
  // Are the unit's possible moves displayed?
  const [activeSelect, setActiveSelect] = useState(false)
  // Is the option to move the unit active?
  const [activeMove, setActiveMove] = useState(false)
  // Is game over ?
  const [endGame, setEndGame] = useState(false)

  // Save previous selection (unit position)
  const [previousSelect, setPreviousSelect] = useState()
  // Save the coordinates of the movement of the left tile
  const [selectedLeftTile, setSelectedLeftTile] = useState('')
  // Save the coordinates of the movement of the right tile
  const [selectedRightTile, setSelectedRightTile] = useState('')

  // We need to differentiate between white and black tiles by tileIndex and rowIndex
  const ColorIndex = (tileIndex, GetRowIndex) => {
    // Is current row odd or even ? (we start at index 0!)
    let isOddRow = GetRowIndex % 2 === 0
    // Is current tile odd or even ? (we start at index 0!)
    // But if we are on an even row, we need to switch the colors !!
    let isWhite = isOddRow ? tileIndex % 2 === 0 : (tileIndex + 1) % 2 === 0
    // Return true or false
    return isWhite
  }

  // We need to find out where the starting units are located and determine their colors
  const SetUnitColor = rowIndex => {
    let newUnit
    // White can only be on row 0 and 1 / Black on 6 and 7 [row ID]
    newUnit = rowIndex < 2 ? 'white' : '' || rowIndex > 5 ? 'black' : ''
    // Return unit color
    return newUnit
  }

  // Check if tile exists
  const CheckTile = (id, shift) => {
    // According to the active player, search in the correct row
    let direction = activeFirstPlayer ? 1 : -1

    // Starting column position "unit" + shift "left or right" + (starting row position (unit) + direction "up or down" * columns count)
    // === ID of corresponding tile !! ***** this needs an explanation with a lecturer's interpretation
    let isTile = GetColumnIndex(id) + shift + (GetRowIndex(id) + direction) * columns
    // Return tile ID
    return isTile
  }

  // Change tile color
  const ChangeTileColor = (id, color, isPossibleMove = true, moveDirection = '') => {
    if (isPossibleMove) {
      // Set color for possible moves (empty tile)
      document.getElementById(Number(id)).style.backgroundColor = color
    } else {
      // Find out the direction of "horizontal" movement (-1 === left, 1 === right)
      moveDirection = moveDirection === 'left' ? -1 : moveDirection === 'right' ? 1 : ''
      // Find out the direction of "vertical" movement ("+" for move down, "- for move up) + moveDirection
      let moveShift = (activeFirstPlayer ? columns : -columns) + moveDirection
      // Set color for impossible moves (another unit on the tile)
      document.getElementById(Number(id)).style.backgroundColor = theme.color.error

      // Check a tile only if it exists on the game board
      if (id + moveShift >= 0 && id + moveShift < columns * rows) {
        // Another unit on tile ?
        if (document.getElementById(Number(id + moveShift)).getAttribute('unit') === '') {
          if (
            // Is the color of another unit the same as the color of the selected unit?
            document.getElementById(Number(id)).getAttribute('color') !==
            (activeFirstPlayer ? 'white' : 'black')
          ) {
            // If === false, then you can jump !!
            if (
              // Find out if it is possible to jump on the tile behind the unit
              GetRowIndex(id) + 1 === GetRowIndex(id + moveShift) ||
              GetRowIndex(id) - 1 === GetRowIndex(id + moveShift)
            ) {
              // If === true, then set color for jump !!
              document.getElementById(Number(id + moveShift)).style.backgroundColor =
                theme.color.jump
            }
          }
        }
      }
    }
  }

  // Reset tile color
  const Deselect = (unitID, leftTileID, rightTileID) => {
    // Reset selected tile color
    ChangeTileColor(unitID, theme.color.lightBg)

    // If left tile exists, reset this tile
    if (GetColumnIndex(unitID) !== 0) {
      ChangeTileColor(leftTileID, theme.color.lightBg)
    }
    // If right tile exists, reset this tile
    if (GetColumnIndex(unitID) !== 7) {
      ChangeTileColor(rightTileID, theme.color.lightBg)
    }

    // Check if exists tile for jump option..
    // Columns * 2 === 16(8*2) it is number of tiles in two rows, -2 or +2 is diagonal shift after jump.. positive value === down, negative value === up
    let checkLeftJump = activeFirstPlayer ? columns * 2 - 2 : -(columns * 2 - 2)
    let checkRightJump = activeFirstPlayer ? columns * 2 + 2 : -(columns * 2 + 2)

    // Check a tile only if it exists on the game board
    if (Number(unitID) + checkLeftJump >= 0 && Number(unitID) + checkLeftJump < columns * rows) {
      // If left tile exists, is there possible option to jump (if true === this tile is "blue")
      if (
        document.getElementById(Number(unitID) + checkLeftJump).style.backgroundColor === 'blue'
      ) {
        // Reset tile color..
        ChangeTileColor(Number(unitID) + checkLeftJump, theme.color.lightBg)
      }
    }
    // Check a tile only if it exists on the game board
    if (Number(unitID) + checkRightJump >= 0 && Number(unitID) + checkRightJump < columns * rows) {
      // If right tile exists, is there possible option to jump (if true === this tile is "blue")
      if (
        document.getElementById(Number(unitID) + checkRightJump).style.backgroundColor === 'blue'
      ) {
        // Reset tile color..
        ChangeTileColor(Number(unitID) + checkRightJump, theme.color.lightBg)
      }
    }
  }

  // Select target by click
  const Select = e => {
    // Do only until the game is over
    if (!endGame) {
      // If target "e" includes 'white' or 'black' then it is a unit !!
      if (e.outerHTML.includes(activeFirstPlayer ? 'white' : 'black')) {
        // Check if the left tile from the unit exists
        let isLeftTile = CheckTile(e.id, -1)
        // Check if the right tile from the unit exists
        let isRightTile = CheckTile(e.id, 1)

        // Save this values into useState for global use, but only if the selection option is not active !!
        if (!activeSelect) {
          setSelectedLeftTile(isLeftTile)
          setSelectedRightTile(isRightTile)
        }

        // If the tiles exist, are they allowed to move? Allowed === empty tiles only !!
        let isPossibleLeftMove = document.getElementById(isLeftTile).getAttribute('unit') === ''
        let isPossibleRightMove = document.getElementById(isRightTile).getAttribute('unit') === ''

        // If select option is not active, do this..
        if (!activeSelect) {
          // Save this select
          setPreviousSelect(e)
          // Activate select option (prevents further selection..)
          setActiveSelect(true)
          // Activate move option (next selection might be to move..)
          setActiveMove(true)

          // Highlight unit position
          ChangeTileColor(e.id, theme.color.secL3)
          // If the unit's position is not on the left edge, it can go left..
          if (GetColumnIndex(e.id) !== 0) {
            // Check if the move is valid and highlight this tile for possible move
            ChangeTileColor(isLeftTile, theme.color.move, isPossibleLeftMove, 'left')
          }
          // If the unit's position is not on the right edge, it can go right..
          if (GetColumnIndex(e.id) !== 7) {
            // Check if the move is valid and highlight this tile for possible move
            ChangeTileColor(isRightTile, theme.color.move, isPossibleRightMove, 'right')
          }
        }

        // Cancel selection..
        // If select option is active and next selection is the same tile..
        if (previousSelect === e) {
          // Reset colors on unit position and possible movements
          Deselect(previousSelect.id, isLeftTile, isRightTile)
          // Reset data from previous select
          setPreviousSelect('')
          // Deactivate selection option
          setActiveSelect(false)
          // Deactivate move option
          setActiveMove(false)
        }
      }

      // If move option is active, do this..
      if (activeMove) {
        Move(e)
      }
    }
  }

  // Move option
  const Move = e => {
    // Move is possible on "green" or "blue" tile
    if (e.style.backgroundColor === 'green' || e.style.backgroundColor === 'blue') {
      // Is pawn on previous selected tile ?
      if (previousSelect.outerHTML.includes('pawn')) {
        // Set on target tile correct image
        e.style.backgroundImage = activeFirstPlayer ? `url(${whitePawn})` : `url(${blackPawn})`
        // End move option
        setActiveMove(false)
        // End select option
        setActiveSelect(false)
        // Remove color value from previous tile
        previousSelect.setAttribute('color', '')
        // Remove unit value from previous tile
        previousSelect.setAttribute('unit', '')
        // Set color value to target tile
        e.setAttribute('color', activeFirstPlayer ? 'white' : 'black')
        // Set unit value to target tile
        e.setAttribute('unit', 'pawn')
        // Remove unit image from previous tile
        previousSelect.style.backgroundImage = `url()`

        // Is target color "blue" ?
        if (e.style.backgroundColor === 'blue') {
          // Is blue tile on left side ? (it is jump !!)
          if (GetColumnIndex(e.id) < GetColumnIndex(selectedLeftTile)) {
            // Remove the opponent's unit image
            document.getElementById(selectedLeftTile).style.backgroundImage = `url()`
            // Remove the opponent's unit color
            document.getElementById(selectedLeftTile).setAttribute('color', '')
            // Remove the opponent's unit type
            document.getElementById(selectedLeftTile).setAttribute('unit', '')
          }
          // Is blue tile on right side ? (it is jump !!)
          if (GetColumnIndex(e.id) > GetColumnIndex(selectedRightTile)) {
            // Remove the opponent's unit image
            document.getElementById(selectedRightTile).style.backgroundImage = `url()`
            // Remove the opponent's unit color
            document.getElementById(selectedRightTile).setAttribute('color', '')
            // Remove the opponent's unit type
            document.getElementById(selectedRightTile).setAttribute('unit', '')
          }
        }

        // Switch player's turn after move
        setActiveFirstPlayer(!activeFirstPlayer)

        // Reset selection defaults
        Deselect(previousSelect.id, selectedLeftTile, selectedRightTile, true)
      }

      // If the move ends up on the opposite side of the board, the unit is promoted to queen === game over !!
      if (Number(e.id) > columns * (rows - 1) || Number(e.id) < columns) {
        // Change unit image
        e.style.backgroundImage = activeFirstPlayer ? `url(${whiteQueen})` : `url(${blackQueen})`
        // Game over !!
        setEndGame(true)
      }
    }
  }

  // Row index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 / 3 = 1,66.. rounded down it is 1 .. this result is index of our clicked row
  const GetRowIndex = id => {
    return Math.floor(id / rows)
  }

  // Column index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 % 3 = 2 .. this result is index of our clicked column
  const GetColumnIndex = id => {
    return id % columns
  }

  // Game layout initialization, do it only once !!
  if (init === true) {
    // Create grid
    const CreateGrid = () => {
      // The first "for" represents rows
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        // The second "for" represents specific cells in rows (in another words === columns)
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          // Tile is specific "single" cell
          newTile = (
            <button
              // We need to differentiate between white and black tiles by tileIndex and rowIndex
              css={
                // Find out what color the field should be
                ColorIndex(tileIndex, rowIndex)
                  ? // Is white
                    [
                      s.tileBtn,
                      s.whiteTile,
                      // Does the unit start on this field? If so what colors ?
                      SetUnitColor(rowIndex) === 'white' ? s.whitePawn : '',
                      SetUnitColor(rowIndex) === 'black' ? s.blackPawn : '',
                    ]
                  : // Is black
                    [s.tileBtn, s.blackTile]
              }
              // Set tile ID
              id={tileIndex}
              // Is there an unit ? If so what colors ?
              color={ColorIndex(tileIndex, rowIndex) ? SetUnitColor(rowIndex) : ''}
              // Is there an unit ? If so what type ? (at the beginning there are only pawns..)
              unit={ColorIndex(tileIndex, rowIndex) ? (SetUnitColor(rowIndex) ? 'pawn' : '') : ''}
              //Set key
              key={columnIndex}
            />
          )
          // Add last tile into row
          newRow.push(newTile)
          // Increase next tile number by 1, the global scope is not reset between cycles !!
          tileIndex++
        }
        // Add last created row into array / grid
        grid.push(newRow)
        // Reset row for next round
        newRow = []
      }
    }
    // Deactivate initialization, only once is needed !!
    setInit(false)
    // Call function CreateGrid() for creating grid
    CreateGrid()
  }

  return (
    <>
      <h1>Dáma</h1>

      <p css={s.p}>
        Pozn. jde o zkrácenou verzi této hry.. hra končí povýšením jednotky na královnu a nelze
        skákat více než jednou v daném tahu! Důvodem je vysoká komplexnost herního mechanismu pro
        výukové účely na tomto levelu. Tato vyšší obtížnost v podobě více typů jednotek s různými
        vlastnosti je řešena až v rámci společného projektu - Šachy (lekce 10)!
      </p>

      {/* Submit has a default property to reset the page */}
      <form>
        <input css={s.reset} type={'submit'} value={'Reset'}></input>
      </form>

      {/* Game layout */}
      <table css={s.tableWrapper}>
        <tbody>
          {!endGame ? (
            <tr>
              {/* White's turn ? */}
              <th colSpan={columns / 2}>
                <button css={[s.tileBtn, s.whiteTile]}>
                  {activeFirstPlayer ? <img css={s.img} src={whiteQueen} alt='White queen' /> : ''}
                </button>
              </th>
              {/* Black's turn ? */}
              <th colSpan={columns / 2}>
                <button css={[s.tileBtn, s.whiteTile]}>
                  {!activeFirstPlayer ? <img css={s.img} src={blackQueen} alt='Black queen' /> : ''}
                </button>
              </th>
            </tr>
          ) : (
            <tr>
              {/* === true */}
              <th colSpan={columns}>
                <button css={[s.tileBtn, s.winTile]}>
                  <img
                    css={s.img}
                    src={activeFirstPlayer ? blackQueen : whiteQueen}
                    alt='Active player'
                  />
                </button>
              </th>
            </tr>
          )}

          {/* Create rows by map */}
          {grid.map((grid, index) => (
            <tr key={index}>
              {/* Create columns by map */}
              {grid.map((tile, index) => (
                // Set onClick event for cell
                <td css={s.cell} key={index} onClick={e => Select(e.target)}>
                  {tile}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
