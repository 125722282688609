/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../../../Reusable'
import * as theme from '../../../theme'
import { css } from '@emotion/react'

// Others
import { WingedHeader } from '../../../bricks/WingedHeader'

// Images
import course from './images/course.webp'
import degree from './images/degree.webp'

// mdi
import { mdiCogPlayOutline } from '@mdi/js'
import Icon from '@mdi/react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const s = {
  degreeDiv: css({
    marginTop: theme.main.spacing.lg,
    marginBottom: theme.main.spacing.md,
  }),
  degree: css({
    width: `70%`,
    position: `relative`,
    left: `50%`,
    transform: `translate(-50%, 0)`,

    [theme.mq.tablet]: {
      width: '100%',
    },
  }),
  ul: css({
    listStyle: `none`,
  }),
  legend: css({
    marginBottom: theme.main.spacing.sm,
    fontSize: theme.main.font.lg,
    textAlign: `right`,

    [theme.mq.tablet]: {
      marginTop: theme.main.spacing.sm,
    },

    [theme.mq.mobile]: {
      marginTop: 0,
      fontSize: theme.main.font.md,
    },
  }),
  curriculum: css({
    width: `50%`,
    margin: `auto`,
    fontSize: theme.main.font.lg,

    [theme.mq.mobile]: {
      width: '85%',
      fontSize: theme.main.font.md,
      marginBottom: theme.main.spacing.sm,
    },
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const HTMLCourse = () => {
  Unit.ModulesInitialization()
  GTM_ListenerInit('react')

  return (
    <>
      <WingedHeader
        img={course}
        name={'HTML & CSS'}
        lecturer={'Matthew Antirix'}
        level={'Základní'}
        req={'Lze zvládnout i bez znalostí'}
        type={'100% online'}
        price={3599}
        discountPrice={1799}
        discountDate={'Sleva do 24.12.2024'}
        duration={'12 hodin + lektor na drátě'}
        basicCourse={{ id: 'A', duration: '12 hodin', paymentLink: '', discountPaymentLink: '' }}
      />

      <Unit.AppContent>
        <Unit.AppH1>
          <strong>Specifikace kurzu</strong>
        </Unit.AppH1>
        <Unit.AppH2>
          <strong>Start příštího kurzu je 6.&nbsp;ledna (&nbsp;pondělí 17:00 - 19:00&nbsp;)</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Online lekce probíhají každé pondělí v časovém rozmezí 17:00 - 19:00. Kurz obsahuje 6
          takovýchto lekcí a trvá tedy v celkovém rozsahu 6 týdnů. Rozhodně nejde o žádný pseudo
          rychlo kurz, jak z tebe vytáhnout peníze a nalít ti do hlavy veškeré informace za jediný
          víkend!. Pokud nestíháš nevadí, lekce jsou nahrávány a ptát se můžeš i mimo toto časové
          okno!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Lektor kurzu k tvým službám</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Lektor tohoto kurzu tu je pro tebe i mimo skupinové online lekce. Na každý týden máš zadán
          i úkol na zpracování. Náš lektor není žádný učitel, tak jak to znáš ze školy, ale tvůj
          kolega / parťák, tak jak tě to bude čekat i při práci v tomto oboru. To znamená, že tu
          není pouze od toho, aby si odučil své hodiny, opravil ti úkol a konec.. V žádném případě,
          mnohdy budou mít úkoly i mnoho správných řešení, nebo nepochopíš co se po tobě chce, nebo
          prostě jen jednoduše nebudeš mít vůbec tucha jak to vyřešit. Lektor je ti v tomto zcela k
          dispozici, máš na něj přímý kontakt a dle jeho časových možností s ním můžeš řešit vše co
          ti bude stát v cestě ke zvládnutí tohoto kurzu!
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Pro koho je kurz určen, co potřebuji znát ?</strong>
        </Unit.AppH2>
        <Unit.AppP>Naprosto pro kohokoliv, jde o úplný základ všeho!</Unit.AppP>

        <Unit.AppH2>
          <strong>Jak kurz probíhá ?</strong>
        </Unit.AppH2>
        <Unit.AppP>
          Základem jsou online lekce, kde se vždy probere dané téma a zadá se úkol na vypracování.
        </Unit.AppP>

        <Unit.AppH2>
          <strong>Skupinový projekt ?</strong>
        </Unit.AppH2>
        <Unit.AppP>Slouží jako hlavní výstup kurzu, kterým se můžeš prezentovat.</Unit.AppP>

        <Unit.AppH2>Komunikační kanály</Unit.AppH2>
        <Unit.AppP>
          <ul css={s.ul}>
            <li>Online lekce: Google meets</li>
            <li>Revize úkolů: Gitlab</li>
            <li>Další komunikace: Discord</li>
          </ul>
        </Unit.AppP>

        <Unit.AppH2>Nějaké další dotazy?</Unit.AppH2>
        <Unit.AppP>
          Pokud tě zajímá i něco dalšího co ti tu chybí, klidně nám napiš na náš email:
          info@hexsquad.cz, nebo rovnou zavolej na tel. 723 584 131.
        </Unit.AppP>

        <Unit.AppH2 alignCenter>
          <strong>Rozpis lekcí dle týdnů</strong>
        </Unit.AppH2>
        <Unit.AppP>
          <ol css={s.curriculum}>
            <li>
              Základy HTML <br />
              (tagy a k čemu slouží)
            </li>
            <li>Layout & CSS (+ flexbox)</li>
            <li>Responzivita (+ bootstrap)</li>

            <Unit.AppH2 alignCenter>
              Závěrečný projekt: <br />
              Vlastní webovka
            </Unit.AppH2>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Začínáme (+ git)
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Blížíme se do finále
              {/* </Unit.AppLink> */}
            </li>
            <li>
              {/* <Unit.AppLink to={courseRoutes.React.chess.to} blank> */}
              Prezentace projektu, <br />+ Q&A, vyhodnocení kurzu
              {/* </Unit.AppLink> */}
            </li>
          </ol>
        </Unit.AppP>

        <div css={s.degreeDiv}>
          <Unit.AppH1 alignCenter>Certifikát kurzu (Vzor)</Unit.AppH1>
          <img src={degree} css={s.degree} alt='Course degree' />
        </div>
      </Unit.AppContent>
    </>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////// GTM ////////////////////////////
const GTM_ListenerInit = (pageType: string) => {
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: pageType,
  })
}
//////////////////////////// GTM ////////////////////////////

const LessonLink = (p: { to: string; title: string }) => (
  <li>
    <span onClick={() => GTM_ListenerInit('lesson')}>
      <Unit.AppLink to={p.to} blank>
        <Icon path={mdiCogPlayOutline} size={1} /> {p.title}
      </Unit.AppLink>
    </span>
  </li>
)
