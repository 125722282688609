/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  center: css({
    margin: `${theme.spacing.xs} auto`,
    textAlign: `center`,
  }),
  inLineBlock: css({
    display: `inline-block`,
  }),
  menu: css({
    display: `flex`,
    justifyContent: `center`,
    justifyContent: `space-between`,

    [mq.tablet]: {
      marginTop: theme.spacing.sm,
    },
  }),
  edit: css({
    color: theme.color.dark,
    padding: theme.spacing.xs,
    backgroundColor: theme.color.main,
  }),
  input: css({
    width: `60%`,
    textAlign: `center`,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: '20px',
    backgroundColor: theme.color.secL3,
  }),
  inputTextArea: css({
    width: `100%`,
    padding: theme.spacing.xs,
    margin: `${theme.spacing.xs} 0`,
    fontSize: theme.font.md,
    textAlign: `justify`,
    border: `none`,
    outline: `none`,
    resize: `none`,
  }),
  deleteAllBtn: css({
    width: `30%`,
    fontSize: theme.font.md,
    fontWeight: `bold`,
    textAlign: `center`,
    border: `none`,
    borderRadius: '20px',
    backgroundColor: `#bbb`,
    cursor: `pointer`,

    [mq.tablet]: {
      width: `35%`,
    },
  }),
  task: css({
    margin: `${theme.spacing.xs} 0`,
    padding: theme.spacing.xs,
    fontSize: theme.font.md,
    textAlign: `justify`,
    borderRadius: '20px',
    backgroundColor: theme.color.secL3,
    color: theme.color.dark,
    overflowWrap: `anywhere`,
  }),
  taskMenu: css({
    width: `100%`,
    marginBottom: theme.spacing.xs,
    display: `flex`,
    justifyContent: `center`,
    justifyContent: `space-between`,
  }),
  taskMenuBtn: css({
    margin: `0 ${theme.spacing.xs}`,
    fontWeight: 'bold',
    border: `none`,
    cursor: `pointer`,
    backgroundColor: `transparent`,
  }),
  taskMenuBox: css({
    marginBottom: theme.spacing.lg,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const ToDoApp = () => {
  // Only for main website .. for the purpose of the course change the next line on true !!!!!!!!!!!!!!!!!!
  let allowCookies = JSON.parse(localStorage.getItem('Cookies')) // let allowCookies = true

  // Main //
  // We need a place to store the data (browser: local storage .. or external server..)
  const localStorageName = 'TodoList'
  // We need task list .. but if a list exists (local storage), we need to load it !!
  const [todoList, setTodoList] = useState(
    JSON.parse(localStorage.getItem(localStorageName))
      ? JSON.parse(localStorage.getItem(localStorageName))
      : []
  )

  // Add //
  // We need to save the new changing task value === what we see while writing
  const [inputValue, setInputValue] = useState('')
  // And after submit, we need entire task value
  const [newTask, setNewTask] = useState()

  // Update //
  // We are editing right now ?
  const [editing, setEditing] = useState(false)
  // Which task we want to edit ?
  const [currentListID, setCurrentListID] = useState('')
  // We need to save the new changing task value === what we see while writing
  const [updateValue, setUpdateValue] = useState('')

  // Search //
  // We are searching right now ?
  const [searching, setSearching] = useState(false)
  // We need to save the new changing task value === what we see while writing
  const [search, setSearch] = useState('')

  // Render //
  // Our current list
  let currentList

  // Perform after all above !!
  // Add new task //
  useEffect(() => {
    // "undefined" check is a safeguard against an unexpected fatal error .. empty task is OK !!
    if (newTask !== undefined) {
      // Add new task into list
      todoList.push(newTask)
      // Reset value for next task
      setNewTask(undefined)
      // If we search at the same time === turn it off !!
      setSearching(false)
    }
    // If we edit at the same time === turn it off !!
    setEditing(false)
    // Perform only after "newTask" or "todoList" change
  }, [newTask, todoList])

  // Check submit and update list //
  const CheckSubmit = e => {
    // "e.key" is a pressed key if it is "enter"..
    if (e.key === 'Enter') {
      // Create new task
      let createNewTask = {
        // ID must be unique, however, we can also delete in the list, so the tasks do not go in order !! We cannot number 1,2,3.. !!
        // But we can generate ID by current time!
        id: new Date().getTime(),
        // Task value
        value: e.target.value,
        // Is complete ? .. default === false
        completed: false,
      }
      // Update new task value
      setNewTask(createNewTask)
    }
  }

  // Edit task //
  const EditTask = e => {
    // We are editing right now ?
    setEditing(true)
    // We need to load the value of the task by ID !!
    // In todoList[we search ID value among all tasks by comparing the value of A and B]
    // We don't care about the data type of the value at this point, we need to disable eslint..
    // eslint-disable-next-line eqeqeq
    setUpdateValue(todoList[todoList.findIndex(item => item.id == e.target.value)].value)
    // Save our ID into useState (we need to get this value out of useEffect)
    setCurrentListID(e.target.value)
  }

  // Check update and update list //
  const CheckUpdate = e => {
    // If enter is pressed (after editing task) there are some changes in our list
    if (e.key === 'Enter') {
      // We need to load the value of the task by ID !!
      // In todoList[we search ID value among all tasks by comparing the value of A and B]
      // We don't care about the data type of the value at this point, we need to disable eslint..
      // But we don't need old task value .. we can simply overwrite it
      // eslint-disable-next-line eqeqeq
      todoList[todoList.findIndex(item => item.id == e.target.id)].value = updateValue
      // However, we need to store the entire list, not just the new value !! We need to use "spread operator" [...operator] !!
      setTodoList([...todoList])
      // And close edit task modal..
      setEditing(false)
    }
  }

  // Remove task //
  const RemoveTask = e => {
    // We need to update our list..
    setTodoList(
      // But without something it is another list..
      todoList.filter(newList => {
        // We need to save the whole list again, but without a specific task
        // We don't care about the data type of the value at this point, we need to disable eslint..
        // eslint-disable-next-line eqeqeq
        return newList.id != e.target.value
      })
    )
  }

  // Remove ALL //
  const RemoveALL = () => {
    // We just empty the whole list..
    setTodoList([])
  }

  // Complete task //
  const CompleteTask = e => {
    // We need to load the value of the task by ID !!
    // In todoList[we search ID value among all tasks by comparing the value of A and B]
    // We don't care about the data type of the value at this point, we need to disable eslint..
    // eslint-disable-next-line eqeqeq
    let isCompleted = todoList.findIndex(item => item.id == e.target.value)
    // Is done or not have only two options "Yes or No", we can just switch current value to opposite one
    todoList[isCompleted].completed = !todoList[isCompleted].completed
    // However, we need to store the entire list, not just the new value !! We need to use "spread operator" [...operator] !!
    setTodoList([...todoList])
  }

  // Search task //
  const SearchTask = e => {
    // "e" is the current search term
    setSearch(e)
    // If is it empty === we are not searching..
    e === '' ? setSearching(false) : setSearching(true)
    // If we edit at the same time === turn it off !!
    setEditing(false)
  }

  // But we need to remember what we are looking for..
  // However, searching does not change the list .. we need to another list
  const filteredTodoList = useMemo(() => {
    // We are searching in todoList..
    return todoList.filter(task => {
      // We only want to return tasks that contain our term, is not case sensitive !!
      return task.value.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    })
    // Perform only after "search" or "todoList" change
  }, [search, todoList])

  // Searching is active === show "filtered" Todo list .. else show Todo list
  searching === false ? (currentList = todoList) : (currentList = filteredTodoList)

  // Perform after all above !!
  // Local Storage save //
  useEffect(() => {
    // Save our Todo list into local storage
    localStorage.setItem(localStorageName, JSON.stringify(todoList))
  })

  return allowCookies ? (
    <>
      <h1>ToDo App</h1>
      <div>
        {/* Tool menu */}
        <div css={s.menu}>
          {/* Search */}
          <input
            css={s.input}
            type='text'
            id='searchInput'
            placeholder={'Hledej výraz..'}
            onChange={e => SearchTask(e.target.value)}
          ></input>
          {/* Delete All */}
          <button css={s.deleteAllBtn} onClick={RemoveALL}>
            {'Smazat VŠE !'}
          </button>
        </div>

        {/* New task  */}
        <form
          onSubmit={e => {
            // Deactivate default behavior
            e.preventDefault()
            // Set default value
            setInputValue('')
          }}
        >
          <div>
            <input
              css={s.inputTextArea}
              autoFocus
              value={inputValue}
              placeholder={'Zapsat další úkol?'}
              // Set current value
              onChange={e => setInputValue(e.target.value)}
              // Submit by key down
              onKeyDown={CheckSubmit}
            />
          </div>
        </form>

        {/* Editing task */}
        {editing === true ? (
          <form
            onSubmit={e => {
              // Deactivate default behavior
              e.preventDefault()
              // Set default value
              setUpdateValue('')
            }}
          >
            <div css={s.edit}>
              <h3>{'Editace úkolu'}:</h3>
              <textarea
                css={s.inputTextArea}
                rows='5'
                autoFocus
                value={updateValue}
                id={currentListID}
                // Set current value
                onChange={e => setUpdateValue(e.target.value)}
                // Submit by key down
                onKeyDown={CheckUpdate}
              />
            </div>
          </form>
        ) : (
          ''
        )}
      </div>
      {/* Task list */}
      <div css={s.taskMenuBox}>
        {/* Map all existing tasks */}
        {currentList.map((task, index) => (
          <div css={s.task} key={index}>
            <div css={s.taskMenu}>
              {/* Set task ID */}
              <h3 css={s.inLineBlock}>Úkol: {index + 1}</h3>
              <div>
                {/* Task tool menu */}
                {/* Done or not ? */}
                <button css={s.taskMenuBtn} value={task.id} onClick={CompleteTask}>
                  {'Splněno'}: {task.completed === true ? '✔️' : '⭕'}
                </button>
                {/* Edit task */}
                <button css={s.taskMenuBtn} value={task.id} onClick={EditTask}>
                  📝
                </button>
                {/* Delete task */}
                <button css={s.taskMenuBtn} value={task.id} onClick={RemoveTask}>
                  ❌
                </button>
              </div>
            </div>

            {/* Task description */}
            <p>{task.value}</p>
          </div>
        ))}
      </div>
    </>
  ) : (
    <>Tato ukázka vyžaduje povolené cookies !!</>
  )
}
