/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react'

// Images
import blackPawn from './images/black_pawn.png'
import blackQueen from './images/black_queen.png'
import whitePawn from './images/white_pawn.png'
import whiteQueen from './images/white_queen.png'

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Theme
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const theme = {
  color: {
    light: '#fff',
    lightBg: '#fff',

    dark: '#000',
    darkBg: '#000',

    main: '#0f0',
    secL1: `#3f3`,
    secL2: `#6f6`,
    secL3: `#9f9`,
    secL4: `#cfc`,
    secD1: `#0c0`,
    secD2: `#090`,
    secD3: `#060`,
    secD4: `#030`,

    move: `green`,
    error: `red`,
    jump: `blue`,
  },

  font: {
    xs: `0.5rem`,
    sm: `0.7rem`,
    md: `1rem`,
    lg: `1.5rem`,
    xl: `2.5rem`,
    xxl: `4rem`,
  },
  spacing: {
    xxs: `0.3rem`,
    xs: `1rem`,
    sm: `1rem`,
    md: `2rem`,
    lg: `4rem`,
    xl: `6rem`,
  },
}

// Responsive for main website version..
export const mq = {
  tablet: `@media (max-width: 850px)`,
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  reset: css({
    marginTop: theme.spacing.sm,
    padding: `0 ${theme.spacing.xs}`,
    textAlign: `center`,
    backgroundColor: theme.color.main,
    fontSize: theme.font.md,
    border: `none`,
    borderRadius: `20px`,
    fontWeight: `bold`,
    cursor: `pointer`,
  }),
  tileBtn: css({
    width: `60px`,
    height: `60px`,
    cursor: `pointer`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `50%`,
    backgroundPosition: `center`,
  }),
  whiteTile: css({
    backgroundColor: theme.color.lightBg,
  }),
  blackTile: css({
    backgroundColor: theme.color.darkBg,
  }),
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const Chess = () => {
  // Initialization settings
  // We need starting grid for game, but we don't want to reset this value after each round !!
  const [init, setInit] = useState(true)
  // Current game layout, what we see in the game
  const [grid, setGrid] = useState([])

  // Variables for our grid
  let newRow = [],
    rows = 8,
    columns = 8,
    newTile,
    // The tileIndex is subject to global scope
    tileIndex = 0

  // Who's turn ?
  const [activeFirstPlayer, setActiveFirstPlayer] = useState(true)
  // Is game over ?
  const [endGame, setEndGame] = useState(false)

  // We need to differentiate between white and black tiles by tileIndex and rowIndex
  const ColorIndex = (tileIndex, GetRowIndex) => {
    // Is current row odd or even ? (we start at index 0!)
    let isOddRow = GetRowIndex % 2 === 0
    // Is current tile odd or even ? (we start at index 0!)
    // But if we are on an even row, we need to switch the colors !!
    let isWhite = isOddRow ? tileIndex % 2 === 0 : (tileIndex + 1) % 2 === 0
    // Return true or false
    return isWhite
  }

  // Row index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 / 3 = 1,66.. rounded down it is 1 .. this result is index of our clicked row
  const GetRowIndex = id => {
    return Math.floor(id / rows)
  }

  // Column index function
  // We have 3x3 grid: If cell index of user' click is 5, then 5 % 3 = 2 .. this result is index of our clicked column
  const GetColumnIndex = id => {
    return id % columns
  }

  // Game layout initialization, do it only once !!
  if (init === true) {
    // Create grid
    const CreateGrid = () => {
      // The first "for" represents rows
      for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
        // The second "for" represents specific cells in rows (in another words === columns)
        for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
          // Tile is specific "single" cell
          newTile = (
            <button
              // We need to differentiate between white and black tiles by tileIndex and rowIndex
              css={
                // Find out what color the field should be
                ColorIndex(tileIndex, rowIndex)
                  ? // Is white
                    [s.tileBtn, s.whiteTile]
                  : // Is black
                    [s.tileBtn, s.blackTile]
              }
              // Set tile ID
              id={tileIndex}
              //Set key
              key={columnIndex}
            />
          )
          // Add last tile into row
          newRow.push(newTile)
          // Increase next tile number by 1, the global scope is not reset between cycles !!
          tileIndex++
        }
        // Add last created row into array / grid
        grid.push(newRow)
        // Reset row for next round
        newRow = []
      }
    }
    // Deactivate initialization, only once is needed !!
    setInit(false)
    // Call function CreateGrid() for creating grid
    CreateGrid()
  }

  return (
    <>
      <h1>Šachy</h1>

      {/* Submit has a default property to reset the page */}
      <form>
        <input css={s.reset} type={'submit'} value={'Reset'}></input>
      </form>

      {/* Game layout */}
      <table css={s.tableWrapper}>
        <tbody>
          {/* Create rows by map */}
          {grid.map((grid, index) => (
            <tr key={index}>
              {/* Create columns by map */}
              {grid.map((tile, index) => (
                // Set onClick event for cell
                <td css={s.cell} key={index}>
                  {tile}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
