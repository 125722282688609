/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// Others

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  docBox: css({
    textAlign: 'justify',
    padding: theme.main.spacing.md,
    marginBottom: theme.main.spacing.xl,

    [theme.mq.mobile]: {
      padding: theme.main.spacing.xs,
    },
  }),
  docLi1: css({
    marginTop: theme.main.spacing.sm,
    marginLeft: theme.main.spacing.md,

    [theme.mq.mobile]: {
      marginLeft: theme.main.spacing.sm,
    },
  }),
  docLi2: css({
    marginTop: theme.main.spacing.sm,
    marginLeft: theme.main.spacing.xl,

    [theme.mq.mobile]: {
      marginLeft: theme.main.spacing.md,
    },
  }),
  ol1: css({}),
  ol2: css({ listStyleType: `lower-alpha` }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const GDPR = () => {
  Unit.ModulesInitialization()

  //////////////////////////// GTM ////////////////////////////
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: 'other',
  })
  //////////////////////////// GTM ////////////////////////////

  return (
    <article css={s.docBox}>
      <Unit.AppH1 alignCenter>Zásady ochrany osobních údajů</Unit.AppH1>
      <Unit.AppH1 alignCenter>I.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Základní ustanovení</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Správcem osobních údajů podle čl. 4 bod 7 nařízení Evropského parlamentu a Rady (EU)
          2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném
          pohybu těchto údajů (dále jen: „GDPR”) je Hex Squad s.r.o. IČ 213&nbsp;68&nbsp;643 se
          sídlem Bělehradská 858/23 Praha 12&nbsp;000 (dále jen: „správce“).
        </li>
        <li css={s.docLi1}>
          Kontaktní údaje správce jsou adresa:
          <ul>
            <li css={s.docLi2}>Bělehradská 858/23 Praha 12&nbsp;000</li>{' '}
            <li css={s.docLi2}>email: info@hexsquad.cz </li>
            <li css={s.docLi2}>telefon: 723 584 131</li>
          </ul>
        </li>
        <li css={s.docLi1}>
          Osobními údaji se rozumí veškeré informace o identifikované nebo identifikovatelné fyzické
          osobě; identifikovatelnou fyzickou osobou je fyzická osoba, kterou lze přímo či nepřímo
          identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační
          číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické,
          fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této
          fyzické osoby.
        </li>
        <li css={s.docLi1}> Správce nejmenoval pověřence pro ochranu osobních údajů.</li>
      </ol>
      <Unit.AppH1 alignCenter>II.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Zdroje a kategorie zpracovávaných osobních údajů</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Správce zpracovává osobní údaje, které jste mu poskytl/a nebo osobní údaje, které správce
          získal na základě plnění Vaší objednávky.
        </li>
        <li css={s.docLi1}>
          Správce zpracovává Vaše identifikační a kontaktní údaje a údaje nezbytné pro plnění
          smlouvy.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>III.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Zákonný důvod a účel zpracování osobních údajů</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>Zákonným důvodem zpracování osobních údajů je </li>
        <ul>
          <li
            css={s.docLi2}
          >{`plnění smlouvy mezi Vámi a správcem podle čl. 6 odst. 1 písm. b) GDPR,`}</li>
          <li css={s.docLi2}>
            {`oprávněný zájem správce na poskytování přímého marketingu (zejména pro zasílání
            obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. f) GDPR,
            `}
          </li>
          <li css={s.docLi2}>
            {`Váš souhlas se zpracováním pro účely poskytování přímého marketingu (zejména pro
            zasílání obchodních sdělení a newsletterů) podle čl. 6 odst. 1 písm. a) GDPR ve spojení
            s § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti
            v případě, že nedošlo k objednávce zboží nebo služby.`}
          </li>
        </ul>
        <li css={s.docLi1}>Účelem zpracování osobních údajů je</li>
        <ul>
          <li css={s.docLi2}>
            vyřízení Vaší objednávky a výkon práv a povinností vyplývajících ze smluvního vztahu
            mezi Vámi a správcem; při objednávce jsou vyžadovány osobní údaje, které jsou nutné pro
            úspěšné vyřízení objednávky (jméno a adresa, kontakt), poskytnutí osobních údajů je
            nutným požadavkem pro uzavření a plnění smlouvy, bez poskytnutí osobních údajů není
            možné smlouvu uzavřít či jí ze strany správce plnit,
          </li>
          <li css={s.docLi2}>
            zasílání obchodních sdělení a činění dalších marketingových aktivit.
          </li>
        </ul>
        <li css={s.docLi1}>
          Ze strany správce nedochází k automatickému individuálnímu rozhodování ve smyslu čl. 22
          GDPR.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>IV. </Unit.AppH1>
      <Unit.AppH2 alignCenter>Doba uchovávání údajů </Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}> Správce uchovává osobní údaje</li>
        <ul>
          <li css={s.docLi2}>
            po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi
            a správcem a uplatňování nároků z těchto smluvních vztahů (po dobu 15 let od ukončení
            smluvního vztahu).
          </li>
          <li css={s.docLi2}>
            po dobu, než je odvolán souhlas se zpracováním osobních údajů pro účely marketingu,
            nejdéle 3 roky, jsou-li osobní údaje zpracovávány na základě souhlasu.
          </li>
        </ul>
        <li css={s.docLi1}>
          {' '}
          Po uplynutí doby uchovávání osobních údajů správce osobní údaje vymaže.{' '}
        </li>
      </ol>
      <Unit.AppH1 alignCenter>V.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Příjemci osobních údajů (subdodavatelé správce)</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}> Příjemci osobních údajů jsou osoby </li>
        <ul>
          <li css={s.docLi2}>
            podílející se na dodání zboží / služeb / realizaci plateb na základě smlouvy,{' '}
          </li>
          <li css={s.docLi2}>podílející se na zajištění provozu služeb,</li>
          <li css={s.docLi2}> zajišťující marketingové služby.</li>
        </ul>

        <li css={s.docLi1}>
          Správce nemá v úmyslu předat osobní údaje do třetí země (do země mimo EU) nebo mezinárodní
          organizaci.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VI.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Vaše práva</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}> Za podmínek stanovených v GDPR máte </li>
        <ul>
          <li css={s.docLi2}>právo na přístup ke svým osobním údajům dle čl. 15 GDPR,</li>
          <li css={s.docLi2}>
            právo opravu osobních údajů dle čl. 16 GDPR, popřípadě omezení zpracování dle čl. 18
            GDPR.
          </li>
          <li css={s.docLi2}>právo na výmaz osobních údajů dle čl. 17 GDPR.</li>
          <li css={s.docLi2}>právo vznést námitku proti zpracování dle čl. 21 GDPR a</li>
          <li css={s.docLi2}>právo na přenositelnost údajů dle čl. 20 GDPR.</li>
          <li css={s.docLi2}>
            právo odvolat souhlas se zpracováním písemně nebo elektronicky na adresu nebo email
            správce uvedený v čl. III těchto podmínek.
          </li>
        </ul>
        <li css={s.docLi1}>
          Dále máte právo podat stížnost u Úřadu pro ochranu osobních údajů v případě, že se
          domníváte, že bylo porušeno Vaší právo na ochranu osobních údajů.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VII.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Podmínky zabezpečení osobních údajů</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Správce prohlašuje, že přijal veškerá vhodná technická a organizační opatření k
          zabezpečení osobních údajů.
        </li>
        <li css={s.docLi1}>
          Správce přijal technická opatření k zabezpečení datových úložišť a úložišť osobních údajů
          v listinné podobě.
        </li>
        <li css={s.docLi1}>
          Správce prohlašuje, že k osobním údajům mají přístup pouze jím pověřené osoby.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VIII.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Závěrečná ustanovení</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Odesláním objednávky z internetového objednávkového formuláře potvrzujete, že jste
          seznámen/a s podmínkami ochrany osobních údajů a že je v celém rozsahu přijímáte.
        </li>
        <li css={s.docLi1}>
          S těmito podmínkami souhlasíte zaškrtnutím souhlasu prostřednictvím internetového
          formuláře. Zaškrtnutím souhlasu potvrzujete, že jste seznámen/a s podmínkami ochrany
          osobních údajů a že je v celém rozsahu přijímáte.
        </li>
        <li css={s.docLi1}>
          Správce je oprávněn tyto podmínky změnit. Novou verzi podmínek ochrany osobních údajů
          zveřejní na svých internetových stránkách, případně Vám zašle novou verzi těchto podmínek
          na e-mailovou adresu, kterou jste správci poskytl/a.
        </li>
      </ol>

      <Unit.AppH2 alignCenter>Tyto podmínky nabývají účinnosti dnem 17.4.2024.</Unit.AppH2>
    </article>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
