/** @jsxImportSource @emotion/react */
// Default
import * as Unit from '../Reusable'
import * as theme from '../theme'
import { css } from '@emotion/react'

// Others
import reclamation from '../localData/Reklamace.odt'
import withdrawal from '../localData/Odstoupení od smlouvy.odt'

// Images

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// CSS
///////////////////////////////////////////////////////////////////////////////////////////////////////////

const s = {
  docBox: css({
    textAlign: 'justify',
    padding: theme.main.spacing.md,
    marginBottom: theme.main.spacing.xl,

    [theme.mq.mobile]: {
      padding: theme.main.spacing.xs,
    },
  }),
  docLi1: css({
    marginTop: theme.main.spacing.sm,
    marginLeft: theme.main.spacing.md,

    [theme.mq.mobile]: {
      marginLeft: theme.main.spacing.sm,
    },
  }),
  docLi2: css({
    marginTop: theme.main.spacing.sm,
    marginLeft: theme.main.spacing.xl,

    [theme.mq.mobile]: {
      marginLeft: theme.main.spacing.md,
    },
  }),
  ol1: css({}),
  ol2: css({ listStyleType: `lower-alpha` }),
  href: css({ color: theme.color.main }),
  footerDiv: css({
    marginTop: theme.main.spacing.lg,
  }),
  downloadBtn: css({
    display: `block`,
    margin: `${theme.main.spacing.sm} 0`,
    border: 'none',
    fontSize: theme.main.font.md,
    fontStyle: 'italic',
    backgroundColor: 'transparent',
    color: theme.color.main,
    cursor: 'pointer',
  }),
} as const

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Main
///////////////////////////////////////////////////////////////////////////////////////////////////////////

export const VOP = () => {
  Unit.ModulesInitialization()

  const downloadButton = (url: any, title: string) => {
    const fileUrl = url
    const link = document.createElement('a')
    link.href = fileUrl
    link.download = title
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  //////////////////////////// GTM ////////////////////////////
  //@ts-ignore
  window.dataLayer = window.dataLayer || []
  //@ts-ignore
  window.dataLayer.push({
    page_type: 'other',
  })
  //////////////////////////// GTM ////////////////////////////

  return (
    <article css={s.docBox}>
      <Unit.AppH1 alignCenter>VŠEOBECNÉ OBCHODNÍ PODMÍNKY</Unit.AppH1>
      <Unit.AppP>
        Tyto všeobecné obchodní podmínky (“Podmínky”) společnosti Hex Squad s.r.o., se sídlem
        Bělehradská 858/23 Praha 12&nbsp;000, IČ:&nbsp;213&nbsp;68&nbsp;643, zapsané v obchodním
        rejstříku pod sp. zn. C 400888 vedeném u Městského soudu v Praze, e-mail: info@hexsquad.cz,
        telefonní číslo: 723&nbsp;584&nbsp;131, adresa provozovny Dr. Březiny 866, Bludov
        789&nbsp;61 („My” nebo „Prodávající”) upravují v souladu s ustanovením § 1751 odst. 1 zákona
        č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů („Občanský zákoník“) vzájemná
        práva a povinnosti Vás, jakožto kupujících, a Nás, jakožto prodávajících, vzniklá v
        souvislosti nebo na základě kupní smlouvy („Smlouva“) uzavřené prostřednictvím naší
        platformy na webových stránkách&nbsp;
        <a href='https://hexsquad.cz/' target='_blank' rel='noreferrer' css={s.href}>
          https://hexsquad.cz/
        </a>
      </Unit.AppP>
      <Unit.AppP>
        Všechny informace o zpracování Vašich osobních údajů jsou obsaženy v Zásadách zpracování
        osobních údajů, která naleznete zde:&nbsp;
        <a href='https://hexsquad.cz/gdpr' target='_blank' rel='noreferrer' css={s.href}>
          https://hexsquad.cz/gdpr
        </a>
      </Unit.AppP>
      <Unit.AppP>
        Ustanovení těchto Podmínek jsou nedílnou součástí Smlouvy. Smlouva a Podmínky jsou
        vyhotoveny v českém jazyce. Znění Podmínek můžeme jednostranně měnit či doplňovat. Tímto
        ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění
        Podmínek.
      </Unit.AppP>
      <Unit.AppP>
        Jak jistě víte, tak komunikujeme primárně na dálku. Proto i pro naši Smlouvu platí, že jsou
        použity prostředky komunikace na dálku, které umožňují, abychom se spolu dohodli bez
        současné fyzické přítomnosti Nás a Vás, a Smlouva je tak uzavřena distančním způsobem a to
        prostřednictvím rozhraní webové stránky („webové rozhraní“).
      </Unit.AppP>
      <Unit.AppP>
        Pokud některá část Podmínek odporuje tomu, co jsme si společně schválili v rámci procesu
        Vašeho nákupu na Našem webu, bude mít tato konkrétní dohoda před Podmínkami přednost.
      </Unit.AppP>
      <Unit.AppH1 alignCenter>I.</Unit.AppH1>
      <Unit.AppH2 alignCenter>
        <Unit.AppH2 alignCenter> NĚKTERÉ DEFINICE</Unit.AppH2>
      </Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Cena / Celková cena je finanční částka, kterou budete hradit za vybraný produkt;
        </li>
        <li css={s.docLi1}> DPH je daň z přidané hodnoty dle platných právních předpisů;</li>
        <li css={s.docLi1}>
          Faktura je daňový doklad vystavený v souladu se zákonem o dani z přidané hodnoty na
          Celkovou cenu;
        </li>
        <li css={s.docLi1}>
          Objednávka je Váš závazný návrh na uzavření Smlouvy o koupi Produktu s Námi;
        </li>
        <li css={s.docLi1}>
          Vy jste osoba nakupující na Našem webu, právními předpisy označovaná jako kupující;
        </li>
        <li css={s.docLi1}> Produkt je vše, co můžete nakoupit na webu. </li>
      </ol>
      <Unit.AppH1 alignCenter>II.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Obecná ustanovení a poučení </Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Koupě Produktu je možná v rámci emailové / telefonické komunikace po odeslání objednávky
          prostřednictvím webového rozhraní.
        </li>
        <li css={s.docLi1}>
          Při nákupu Produktu je Vaše povinnost poskytnout Nám všechny informace správně a pravdivě.
          Informace, které jste Nám poskytli v Objednávce budeme tedy považovat za správné a
          pravdivé.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>III.</Unit.AppH1>
      <Unit.AppH2 alignCenter>UZAVŘENÍ SMLOUVY</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>Smlouvu s Námi je možné uzavřít pouze v českém jazyce.</li>
        <li css={s.docLi1}>
          Smlouva je uzavírána na dálku prostřednictvím webového rozhraní, přičemž náklady na
          použití komunikačních prostředků na dálku hradíte Vy. Tyto náklady se však nijak neliší od
          základní sazby, kterou hradíte za používání těchto prostředků (tedy zejména za přístup k
          internetu), žádné další náklady účtované Námi tedy nad rámec Celkové ceny nemusíte
          očekávat. Odesláním Objednávky souhlasíte s tím, že prostředky komunikace na dálku
          využíváme.
        </li>
        <li css={s.docLi1}>
          K tomu, abychom mohli Smlouvu uzavřít, je třeba, abyste na webu vytvořili Objednávku. V
          tomto návrhu musí být uvedeny následující údaje:
          <ol css={s.ol2}>
            <li css={s.docLi2}>
              Informace o nakupovaném Produktu (na webu označujete Produkt, o jehož nákup máte
              zájem, tlačítkem / ikonou „Zapsat se“);
            </li>
            <li css={s.docLi2}>
              Informace o způsobu platby a Celkové ceny; tyto informace budou zadány v rámci tvorby
              Objednávky v rámci webového rozhraní, přičemž informace o Celkové ceně budou uvedeny
              automaticky na základě Vámi zvoleného Produktu a platby;
            </li>
            <li css={s.docLi2}>
              Své identifikační a kontaktní údaje sloužící k tomu, abychom mohli doručit Produkt,
              zejména tedy jméno, příjmení, doručovací adresu, telefonní číslo a e-mailovou adresu.
            </li>
          </ol>
        </li>
        <li css={s.docLi1}>
          III.4. V průběhu tvorby Objednávky může až do doby jejího dokončení údaje měnit a
          kontrolovat. Po provedení kontroly prostřednictvím stisku tlačítka „Zapsat se!“ Objednávku
          dokončíte. Před stiskem tlačítka musíte ale ještě potvrdit Vaše seznámení se a souhlas s
          těmito Podmínkami a GDPR, v opačném případě nebude možné Objednávku dokončit. K potvrzení
          a souhlasu slouží zatrhávací políčko. Po stisku tlačítka „Zapsat se!!“ budou všechny
          vyplněné informace odeslány přímo Nám.
        </li>
        <li css={s.docLi1}>
          Vaši Objednávku Vám v co nejkratší době poté, kdy Nám bude doručena, potvrdíme zprávou
          odeslanou na Vaši e-mailovou adresu zadanou v Objednávce. Součástí potvrzení bude shrnutí
          Objednávky, odkaz na platební bránu Comgate a tyto Podmínky formou přílohy e-mailové
          zprávy. Podmínky ve znění účinném ke dni Objednávky, tj. ve znění přiloženém jako příloha
          potvrzující e-mailové zprávy, tvoří nedílnou součást Smlouvy. Potvrzením Objednávky
          dochází k uzavření Smlouvy mezi Námi a Vámi.
        </li>
        <li css={s.docLi1}>
          V případě, že v rámci webového rozhraní nebo v Objednávce bude uvedena zjevně chybná Cena,
          nejsme povinni Vám Produkt za tuto Cenu dodat ani v případě, kdy jste obdrželi potvrzení
          Objednávky, a tedy došlo k uzavření Smlouvy. V takové situaci Vás budeme bezodkladně
          kontaktovat a zašleme Vám nabídku na uzavření nové Smlouvy v pozměněné podobě oproti
          Objednávce. Nová Smlouva je v takovém případě uzavřena ve chvíli, kdy Naši nabídku
          potvrdíte. Za zjevnou chybu v Ceně se považuje například situace, kdy Cena neodpovídá
          obvyklé ceně u jiných prodejců nebo chybí či přebývá cifra.
        </li>
        <li css={s.docLi1}>
          V případě, kdy dojde k uzavření Smlouvy, Vám vzniká závazek k zaplacení Celkové ceny.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>IV.</Unit.AppH1>
      <Unit.AppH2 alignCenter>CENOVÉ A PLATEBNÍ PODMÍNKY, VÝHRADA VLASTNICKÉHO PRÁVA</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Cena je vždy uvedena v rámci webového rozhraní, v návrhu Objednávky a samozřejmě ve
          Faktuře. V případě rozporu mezi Cenou uvedenou u Produktu v rámci webového rozhraní a
          Cenou uvedenou v návrhu Objednávky se uplatní Cena uvedená v návrhu Objednávky, která bude
          vždy totožná s cenou ve Faktuře.
        </li>
        <li css={s.docLi1}>
          Celková cena je uvedena včetně DPH včetně veškerých poplatků stanovených zákonem.
        </li>
        <li css={s.docLi1}>
          Online platby pro nás zajišťuje platební brána Comgate. Poskytovatel služby, společnost
          Comgate a.s. je licencovaná Platební instituce působící pod dohledem České národní banky.
          Platby probíhající skrze platební bránu jsou plně zabezpečeny a veškeré informace jsou
          šifrovány. Další informace a kontakty naleznete na stránce &nbsp;
          <a
            href='https://www.comgate.cz/cz/platebni-brana'
            target='_blank'
            rel='noreferrer'
            css={s.href}
          >
            Comgate - platební brána
          </a>
          .
        </li>
        <li css={s.docLi1}>
          Platbu Celkové ceny po Vás budeme požadovat po uzavření Smlouvy a před předáním Produktu.
          Úhradu Celkové ceny můžete provést následujícími způsoby:
          <ol css={s.ol2}>
            <li css={s.docLi2}>
              <a
                href='https://help.comgate.cz/v1/docs/cs/platby-kartou'
                target='_blank'
                rel='noreferrer'
                css={s.href}
              >
                Platební kartou
              </a>
              &nbsp;přes platební bránu Comgate, přičemž platba se řídí podmínkami této platební
              brány, které jsou dostupné na:&nbsp;
              <a
                href='https://www.comgate.cz/smluvni-dokumenty?cms_cms_user=78&cms_related_cms_activity=lbq186%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_us'
                target='_blank'
                rel='noreferrer'
                css={s.href}
              >
                Comgate - Smluvní dokumenty
              </a>
              .
            </li>

            <li css={s.docLi2}>
              <a
                href='https://help.comgate.cz/docs/bankovni-prevody'
                target='_blank'
                rel='noreferrer'
                css={s.href}
              >
                Bankovním převodem
              </a>
              &nbsp;přes platební bránu Comgate, přičemž platba se řídí podmínkami této platební
              brány, které jsou dostupné na:&nbsp;
              <a
                href='https://www.comgate.cz/smluvni-dokumenty?cms_cms_user=78&cms_related_cms_activity=lbq186%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_user%3D78%3Fcms_cms_us'
                target='_blank'
                rel='noreferrer'
                css={s.href}
              >
                Comgate - Smluvní dokumenty
              </a>
              .
            </li>
          </ol>
        </li>
        <li css={s.docLi1}>
          Faktura bude vystavena v elektronické podobě po uhrazení Celkové ceny a bude zaslána na
          Vaši e-mailovou adresu.
        </li>
        <li css={s.docLi1}>
          Vlastnické právo k Produktu na Vás přechází až poté, co zaplatíte Celkovou cenu. V případě
          platby bankovním převodem je Celková cena zaplacena připsáním na Náš účet, v ostatních
          případech je zaplacena v okamžik provedení platby.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>V.</Unit.AppH1>
      <Unit.AppH2 alignCenter>DORUČENÍ PRODUKTU</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Produkt včetně všech náležitostí a pokynů Vám bude doručen nejpozději do 5 pracovních dní
          na Vaši emailovou adresu.
        </li>
        <li css={s.docLi1}>
          Nebezpeční škody na Produkt na Vás přechází v okamžiku, kdy ho odešleme.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VI.</Unit.AppH1>
      <Unit.AppH2 alignCenter>PRÁVA Z VADNÉHO PLNĚNÍ</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Zaručujeme, že v době přechodu nebezpečí škody na Produkt podle čl. V.2 Podmínek je
          Produkt bez vad, zejména pak, že Produkt:
        </li>
        <ol css={s.ol2}>
          <li css={s.docLi2}>
            odpovídá ujednanému popisu, druhu a množství, jakož i jakosti, funkčnosti,
            kompatibilitě, interoperabilitě a jiným ujednaným vlastnostem;
          </li>
          <li css={s.docLi2}> je vhodný k účelu, pro který ho požadujete a s nímž souhlasíme;</li>
          <li css={s.docLi2}> je dodán s ujednaným příslušenstvím a pokyny k použití;</li>
        </ol>
        <li css={s.docLi1}>
          Práva a povinnosti ohledně práv z vadného plnění se řídí příslušnými obecně závaznými
          právními předpisy (zejména ustanoveními § 2099 až 2117 a § 2161 až 2174b Občanského
          zákoníku a zákonem č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů).
        </li>
        <li css={s.docLi1}>
          V případě, že bude mít Produkt vadu, tedy zejména pokud nebude splněna některá z podmínek
          dle čl. 6.1, můžete Nám takovou vadu oznámit a uplatnit práva z vadného plnění (tedy
          Produkt reklamovat) zasláním e-mailu na Naši emailovou adresu uvedenou u Našich
          identifikačních údajů. Pro reklamaci můžete využít také vzorový formulář poskytovaný z
          Naší strany, který tvoří přílohu č. 1 Podmínek. V uplatnění práva z vadného plnění je
          třeba zvolit, jak chcete vadu vyřešit, přičemž tuto volbu nemůžete následně bez Našeho
          souhlasu změnit. Reklamaci vyřídíme v souladu s Vámi uplatněným právem z vadného plnění.
        </li>
        <li css={s.docLi1}>
          Má-li Produkt vadu, máte následující práva:
          <ol css={s.ol2}>
            <li css={s.docLi2}>
              na odstranění vady dodáním nového Produktu bez vady, nebo dodáním chybějící části
              Produktu; nebo
            </li>
            <li css={s.docLi2}>
              na odstranění vady Produktu, dle individuální dohody, ledaže by byl zvolený způsob
              odstranění vady nemožný nebo ve srovnání s druhým způsobem nepřiměřeně nákladný, což
              se posoudí zejména s ohledem na význam vady, hodnotu, kterou by Produkt měl bez vady,
              a to, zda může být druhým způsobem vada odstraněna bez značných obtíží pro vás.
            </li>
          </ol>
        </li>
        <li css={s.docLi1}>
          Jsme oprávněni odmítnout vadu odstranit, je-li to nemožné nebo nepřiměřeně nákladné
          zejména s ohledem na význam vady a hodnotu, kterou by Produkt měl bez vady.
        </li>
        <li css={s.docLi1}>
          Dále máte právo na:
          <ol css={s.ol2}>
            <li css={s.docLi2}> přiměřenou slevu z Ceny; nebo</li>
            <li css={s.docLi2}> odstoupení od Smlouvy, </li>
          </ol>
          jestliže:
          <ol css={s.ol2}>
            <li css={s.docLi2}>
              odmítneme vadu odstranit nebo ji neodstraníme v souladu s právními předpisy;
            </li>
            <li css={s.docLi2}> se vada projeví opakovaně,</li>
            <li css={s.docLi2}> je vada podstatným porušením Smlouvy; nebo</li>
            <li css={s.docLi2}>
              je z našeho prohlášení nebo z okolností zjevné, že vada nebude odstraněna v přiměřené
              době nebo bez značných obtíží pro Vás.
            </li>
          </ol>
        </li>
        <li css={s.docLi1}>
          Právo na odstoupení od Smlouvy nenáleží v případě, je-li vada Produktu nevýznamná.
        </li>
        <li css={s.docLi1}>
          V případě, že jste si vadu na Produktu způsobili sami, práva z vadného plnění Vám
          nenáleží.
        </li>
        <li css={s.docLi1}>
          Při uplatnění reklamace Vám vystavíme písemné potvrzení, ve kterém bude uvedeno:
          <ol css={s.ol2}>
            <li css={s.docLi2}> datum, kdy jste reklamaci uplatnili;</li>
            <li css={s.docLi2}> co je obsahem reklamace;</li>
            <li css={s.docLi2}> jaký způsob vyřízení reklamace požadujete;</li>
            <li css={s.docLi2}>
              Vaše kontaktní údaje pro účely poskytnutí informace o vyřízení reklamace.
            </li>
          </ol>
        </li>
        <li css={s.docLi1}>
          Nedohodneme-li se na delší lhůtě, do 30 dnů od obdržení reklamace odstraníme vady a
          poskytneme Vám informaci o vyřízení reklamace na uvedené kontaktní údaje. Pokud tato lhůta
          marně uplyne, můžete odstoupit od Smlouvy nebo požadovat přiměřenou slevu.
        </li>
        <li css={s.docLi1}>
          O vyřízení reklamace Vás budeme informovat e-mailem a vydáme Vám potvrzení o datu a
          způsobu vyřízení reklamace. Pokud je reklamace oprávněná, náleží Vám náhrada účelně
          vynaložených nákladů. Tyto náklady jste povinni prokázat, např. účtenkami či potvrzeními o
          ceně za dopravu.
        </li>
        <li css={s.docLi1}>
          V případě, že jste podnikateli, je Vaší povinností oznámit a vytknout vadu bez zbytečného
          odkladu poté, co jste ji mohli zjistit, nejpozději však do tří dnů od převzetí Produktu.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VII.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Odstoupení od smlouvy</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          K odstoupení od Smlouvy, tedy k ukončení smluvního vztahu mezi Námi a Vámi od jeho
          počátku, může dojít z důvodů a způsoby uvedenými v tomto článku, případně v dalších
          ustanoveních Podmínek, ve kterých je možnost odstoupení výslovně uvedena.
        </li>
        <li css={s.docLi1}>
          V případě, že jste spotřebitel, tedy osoba kupující Produkt mimo rámec své podnikatelské
          činnosti, máte v souladu s ustanovením §1829 občanského zákoníku právo odstoupit od
          Smlouvy bez udání důvodu ve lhůtě 14 dnů ode dne uzavření Smlouvy, resp. pokud se jedná o
          koupi Produktu, pak do čtrnácti dnů od jeho převzetí. V případě, že jsme uzavřeli Smlouvu,
          jejímž předmětem je několik položek Produktů nebo dodání několika částí Produktů, začíná
          tato lhůta běžet až dnem dodání posledního kusu nebo části Produktu, a v případě, že jsme
          uzavřeli Smlouvu, na základě které Vám budeme Produkt dodávat pravidelně a opakovaně,
          začíná běžet dnem dodání první dodávky.
        </li>
        <li css={s.docLi1}>
          Od Smlouvy můžete odstoupit jakýmkoliv prokazatelným způsobem (zejména zasláním e-mailu na
          Naši emailovou adresu uvedenou u Našich identifikačních údajů). Pro odstoupení můžete
          využít také vzorový formulář poskytovaný z Naší strany, který tvoří přílohu č. 2 Podmínek.
        </li>
        <li css={s.docLi1}>
          Ani jako spotřebitel však nemůžete od Smlouvy odstoupit v případech, kdy je předmětem
          Smlouvy plnění uvedené v § 183VII Občanského zákoníku.
        </li>
        <li css={s.docLi1}>
          Lhůta k odstoupení dle čl. VII.2 Podmínek se považuje za zachovanou, pokud Nám v jejím
          průběhu odešlete oznámení, že od Smlouvy odstupujete.
        </li>
        <li css={s.docLi1}>
          V případě odstoupení od Smlouvy dle čl. VII.2 Podmínek jste povinní se vzdát všech
          přístupových práv k Produktu.
        </li>
        <li css={s.docLi1}>
          V případě odstoupení od Smlouvy Vám bude Cena vrácena do 14 dnů ode dne účinnosti
          odstoupení na účet, ze kterého byla připsána, případně na účet zvolený odstoupení od
          Smlouvy. Částka však nebude vrácena dříve, než pozbydete přístupová práva k Produktu.
        </li>
        <li css={s.docLi1}>
          V případě odstoupení od Smlouvy dle čl. VII.2 Podmínek Nám však odpovídáte za snížení
          hodnoty Produktu, které vzniklo v důsledku nakládání s tímto Produktem jinak, než je nutné
          k tomu, abyste se seznámili s povahou, vlastnostmi a funkčností Produktu, tj. způsobem,
          jakým byste se s Produktem seznamovali v kamenné prodejně. V případě, že jsme Vám ještě
          nevrátili Cenu, jsme oprávněni pohledávku z titulu nákladů započíst na Vaši pohledávku na
          vrácení Ceny.
        </li>
        <li css={s.docLi1}>
          My jsme oprávněni odstoupit od Smlouvy kdykoliv před tím, než Vám dodáme Produkt, pokud
          existují objektivní důvody, proč není možné Produkt dodat (zejména důvody na straně
          třetích osob nebo důvody spočívající v povaze Produktu), a to i před uplynutím doby
          uvedené v čl. V.1 Podmínek. Můžeme také od Smlouvy odstoupit, pokud je zjevné, že jste
          uvedli v Objednávce záměrně nesprávné informace. V případě, že nakupujete Produkt v rámci
          své podnikatelské činnosti, tedy jako podnikatel, jsme oprávněni od Smlouvy odstoupit
          kdykoli, i bez udání důvodu.
        </li>
      </ol>
      <Unit.AppH1 alignCenter>VIII.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Řešení sporů se spotřebiteli</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          {`
          Nejsme ve vztahu ke kupujícím vázáni žádnými kodexy chování ve smyslu ustanovení § 1826
          odst. 1 písm. e) Občanského zákoníku.`}
        </li>
        <li css={s.docLi1}>
          Vyřizování stížností spotřebitelů zajišťujeme prostřednictvím elektronické adresy
          info@hexsquad.cz. Informaci o vyřízení stížnosti zašleme na elektronickou adresu
          kupujícího.
        </li>
        <li css={s.docLi1}>
          K mimosoudnímu řešení spotřebitelských sporů ze Smlouvy je příslušná Česká obchodní
          inspekce, se sídlem Štěpánská 796/44, 110&nbsp;00 Praha 1, IČ: 000&nbsp;20&nbsp;869,
          internetová adresa: http://www.coi.cz. Platformu pro řešení sporů on-line nacházející se
          na internetové adrese http://ec.europa.eu/consumers/odr je možné využít při řešení sporů
          mezi prodávajícím a kupujícím, který je spotřebitelem, z kupní smlouvy uzavřené
          elektronickými prostředky.
        </li>
        <li css={s.docLi1}>
          Evropské spotřebitelské centrum Česká republika, se sídlem Štěpánská 796/44, 110&nbsp;00
          Praha 1, internetová adresa: http://www.evropskyspotrebitel.cz je kontaktním místem podle
          Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013, o řešení
          spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES
          (nařízení o řešení spotřebitelských sporů on-line).
        </li>
      </ol>
      <Unit.AppH1 alignCenter>IX.</Unit.AppH1>
      <Unit.AppH2 alignCenter>Závěrečná ustanovení</Unit.AppH2>
      <ol css={s.ol1}>
        <li css={s.docLi1}>
          Pokud Náš a Váš právní vztah obsahuje mezinárodní prvek (tedy například budeme zasílat
          Produkt mimo území České republiky), bude se vztah vždy řídit právem České republiky.
          Pokud jste však spotřebitelé, nejsou tímto ujednáním dotčena Vaše práva plynoucí z
          právních předpisů.
        </li>
        <li css={s.docLi1}>
          Veškerou písemnou korespondenci si s Vámi budeme doručovat elektronickou poštou. Naše
          e-mailová adresa je uvedena u Našich identifikačních údajů. My budeme doručovat
          korespondenci na Vaši e-mailovou adresu uvedenou ve Smlouvě, v Uživatelském účtu nebo přes
          kterou jste nás kontaktovali.
        </li>
        <li css={s.docLi1}>
          Smlouvu je možné měnit pouze na základě naší písemné dohody. My jsme však oprávněni změnit
          a doplnit tyto Podmínky, tato změna se však nedotkne již uzavřených Smluv, ale pouze
          Smluv, které budou uzavřeny po účinnosti této změny. O změně Vás však budeme informovat
          pouze v případě, že Vám na základě Smlouvy máme dodávat Produkt pravidelně a opakovaně.
          Informace o změně Vám zašleme na Vaši e-mailovou adresu nejméně 14 dní před účinností této
          změny. Pokud od Vás do 14 dnů od zaslání informace o změně neobdržíme výpověď uzavřené
          Smlouvy na pravidelné a opakované dodávky Produktu, stávají se nové podmínky součástí naší
          Smlouvy a uplatní se na další dodávku Produktu následující po účinnosti změny. Výpovědní
          doba v případě, že výpověď podáte, činí 2 měsíce.
        </li>
        <li css={s.docLi1}>
          V případě vyšší moci nebo událostí, které nelze předvídat (přírodní katastrofa, pandemie,
          provozní poruchy, výpadky subdodavatelů apod.), neneseme odpovědnost za škodu způsobenou v
          důsledku nebo souvislosti s případy vyšší moci, a pokud stav vyšší moci trvá po dobu delší
          než 10 dnů, máme My i Vy právo od Smlouvy odstoupit.
        </li>
        <li css={s.docLi1}>
          Přílohou Podmínek je vzorový formulář pro reklamaci a vzorový formulář pro odstoupení od
          Smlouvy.
        </li>
        <li css={s.docLi1}>
          Smlouva včetně Podmínek je archivována v elektronické podobě u Nás, ale není Vám
          přístupná. Vždy však tyto Podmínky a potvrzení Objednávky se shrnutím Objednávky obdržíte
          e-mailem a budete tedy mít vždy přístup ke Smlouvě i bez Naší součinnosti. Doporučujeme
          vždy potvrzení Objednávky a Podmínky uložit.
        </li>

        <li css={s.docLi1}>
          <Unit.AppH2>Tyto Podmínky nabývají účinnosti dne 25.04.2024.</Unit.AppH2>
        </li>
      </ol>

      {/* @ts-ignore */}
      <aside css={s.footerDiv}>
        <button onClick={() => downloadButton(reclamation, 'Reklamace')} css={s.downloadBtn}>
          Download - Reklamační formulář
        </button>
        {/* @ts-ignore */}
        <button
          onClick={() => downloadButton(withdrawal, 'Odstoupení od smlouvy')}
          css={s.downloadBtn}
        >
          Download - Formulář pro odstoupení od smlouvy
        </button>
      </aside>
    </article>
  )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// Components
///////////////////////////////////////////////////////////////////////////////////////////////////////////
